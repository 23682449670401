// formats price according to requested currency

import numeral from "numeral"
import locales from "../db/locales.js"

export default function (value, locale) {
  let ll = locales
  let l = locale

  if (!ll || !ll[l]) return ""

  let f = ll[l]

  value = parseFloat(value)

  numeral.localeData().delimiters.thousands = f.numeral.delimiters.thousands

  return numeral(value).format(f.numeral.format)
}

<template>
  <div :class="ec('root')">
    <div :class="ec('wrap')" @click="onItemClick">
      <!-- image -->
      <div :class="ec('image')">
        <p
          class="absolute center"
          v-if="
            display == 'unavailable' && soldOutAppearance && soldOutAppearance.imageLabelOverlay
          "
        >
          vyprodáno
        </p>
        <PlayIcon
          class="w-10 h-auto absolute center z-10 text-white"
          v-if="productKind == 'virtual' && productSubKind == 'video-on-demand'"
        />
        <OneImage
          :src="imageUrl"
          :imageId="imageId"
          kind="shop"
          :kindId="imageKindId"
          :grayscale="imageGrayscale"
          :ratio="imageRatio"
          :preload="true"
          :key="`${imageId}-${expanded}`"
        ></OneImage>
      </div>

      <!-- detail -->
      <div :class="ec('infoWrap')">
        <div>
          <!-- title -->
          <OneHeading
            :level="4"
            class="pt-2"
            align="left"
            :classOverride="this.defaultClasses.subcomponents.title"
            :content="itemTitleWithCount"
          />

          <OneParagraph :content="itemDesc" class="paragraph" />
        </div>

        <!-- price -->
        <OneParagraph
          class="price"
          :classOverride="this.defaultClasses.subcomponents.price"
          align="left"
          color="primary"
          :content="priceShown"
        />
      </div>
    </div>

    <!-- item detail -->
    <div class="pt-4" v-if="expanded">
      <!-- add to list | +/- -->
      <div class="w-full flex flex-row justify-end" v-if="addToListVisible">
        <OneButton label="přidat" @update:click="addToList" v-if="!itemOnList" />
        <OneButtonPlusMinus @add="addMore" @subtract="subtract" v-if="itemOnList" />
      </div>

      <!-- sold out notice -->
      <!-- TODO: move this to particular variant -->
      <div class="px-6 py-4 rounded-md mx-2 my-8 border" v-if="display == 'unavailable'">
        {{ Langs.get("sold-out") }}
      </div>

      <!-- preorder availability -->
      <div class="mt-2" v-if="showDeliveryMethodConstraints">
        <DeliveryMethodConstraints align="right" :product="selectedVariant" />
      </div>

      <!-- ###################################### -->
      <!-- if you change anything here, update components/blocks/commerce/ProductDetail.vue accordingly -->

      <!-- variants toggle -->
      <Variants
        :selectedVariant="selectedVariant"
        @select="selectVariant"
        v-if="showVariantsSwitch"
      />

      <!-- customer fields -->
      <CustomerForm
        class="mt-6"
        layout="horizontal"
        :selectedVariant="selectedVariant"
        @updated="customerFormUpdated"
        v-if="selectedVariant.config.customerForm"
      />

      <!-- package pricing -->
      <PricePackage
        v-model="packageUserConfig"
        :selectedVariant="selectedVariant"
        v-if="selectedVariant.config.priceKind == 'package'"
        layout="horizontal"
        :key="`pricePackage-${selectedVariant.id}`"
      />

      <!-- voluntary pricing -->
      <PriceVoluntary
        class="my-2"
        :selectedVariant="selectedVariant"
        :voluntaryPrice="voluntaryPrice"
        layout="horizontal"
        @updated="voluntaryPriceUpdated"
        v-if="selectedVariant.config.priceKind == 'voluntary'"
      />

      <!-- gift card config -->
      <GiftCard
        :selectedVariant="selectedVariant"
        :variantUserOptions="variantUserOptions"
        @updated="variantUserOptionsUpdated"
        v-if="isGiftCard"
      />
      <!-- ###################################### -->
    </div>
  </div>
</template>

<script>
import Utils from "@/utils"
import Langs from "@/langs"
import {calculate} from "@platformaone/common/commerce"
import mixinProductDetail from "@/mixins/productDetail"

import GiftCard from "@/components/blocks/commerce/ProductDetail/GiftCard.vue"
import PriceVoluntary from "@/components/blocks/commerce/ProductDetail/PriceVoluntary.vue"
import PricePackage from "@/components/blocks/commerce/ProductDetail/PricePackage.vue"
import Variants from "./ProductListingItem/Variants.vue"
import CustomerForm from "@/components/blocks/commerce/ProductDetail/CustomerForm.vue"
import DeliveryMethodConstraints from "@/components/elements/commerce/cart/itemListItem/DeliveryMethodConstraints.vue"
import {useShopCartStore} from "@/stores/shopCart"
import {useShopShoppingListStore} from "@/stores/shopShoppingList"
import {useSiteStore} from "@/stores/site"
import {storeToRefs} from "pinia"
import {PlayIcon} from "@heroicons/vue/24/solid"

export default {
  name: "ProductListingItemList",
  mixins: [mixinProductDetail],
  props: {
    itemDetailDisplay: {
      type: String,
      default: "page", // page || inline
    },
    itemDetailPageId: String,
    itemSKU: String,
    display: String,
    soldOutAppearance: Object,
    imageUrl: [String, Boolean],
    imageId: [String, Boolean],
    imageKindId: String,
    imageGrayscale: [String, Boolean],
    imageRatio: String,
    item: Object,
    itemTitle: String,
    itemDesc: String,
    priceLabel: String,
    productKind: String,
    productSubKind: String,
  },
  components: {
    PlayIcon,
    GiftCard,
    PriceVoluntary,
    PricePackage,
    Variants,
    CustomerForm,
    DeliveryMethodConstraints,
  },
  setup() {
    // stores
    const shopCartStore = useShopCartStore()
    const shopShoppingListStore = useShopShoppingListStore()
    const siteStore = useSiteStore()

    // states
    const {cart: shopCart, frontend: shopCartFrontend} = storeToRefs(shopCartStore)
    const {items: shopShoppingListItems} = storeToRefs(shopShoppingListStore)

    // getters
    const {siteLangCurrent} = storeToRefs(siteStore)

    // actions
    const {
      add: shopShoppingListAdd,
      subtract: shopShoppingListSubtract,
      remove: shopShoppingListRemove,
      updateSnapshot: shopShoppingListUpdateSnapshot,
    } = shopShoppingListStore

    return {
      siteLangCurrent,
      shopCart,
      shopCartFrontend,
      shopShoppingListAdd,
      shopShoppingListSubtract,
      shopShoppingListRemove,
      shopShoppingListUpdateSnapshot,
      shopShoppingListItems,
    }
  },
  data: () => ({
    Langs,
    expanded: false,
    selectedProduct: null,
    selectedVariant: null,
    elementClasses: {
      root: {
        contracted: "pb-6 md:pb-12",
        expanded: "pb-8",
      },
      wrap: {
        contracted:
          "wrap flex flex-row-reverse hover:opacity-70 transition-all duration-500 ease-in-out cursor-pointer",
        expanded: "wrap flex flex-col",
      },
      infoWrap: {
        contracted: "info-wrap flex flex-col justify-between items-start grow",
        expanded: "info-wrap",
      },
      image: {
        contracted: "image flex items-center justify-center shrink-0 w-1/3 md:w-1/5",
        expanded: "image w-full h-32",
      },
    },
    defaultClasses: {
      base: "",
      props: {},
      subcomponents: {
        image: {
          base: "+ min-h-full",
        },
        title: {
          base: "font-primary break-word leading-tight font-bold pt-2",
          props: {
            level: {
              3: "text-base",
            },
          },
        },
        price: {
          base: "+ font-secondary pt-1",
        },
      },
    },
  }),
  computed: {
    currentLang() {
      return this.siteLangCurrent
    },
    shoppingList() {
      return this.shopShoppingListItems
    },
    addToListVisible() {
      return ["available", "preorder"].includes(this.display)
    },
    itemOnList() {
      return this.shopShoppingListItems.find((i) => i.id == this.selectedVariant.id)
    },
    itemInstancesOnList() {
      // there could be multiple instances for package products
      return this.shopShoppingListItems.filter((i) => i.id == this.selectedVariant.id)
    },
    itemOnListCount() {
      let count = 0
      this.itemInstancesOnList.forEach((i) => (count += i.count))
      return count
    },
    itemTitleWithCount() {
      if (this.itemOnList) {
        return `<span class='text-primary'>${this.itemOnListCount} x</span> ${this.itemTitle}`
      } else {
        return this.itemTitle
      }
    },
    priceShown() {
      // when product opened, variant is chosen, so we display particular price
      if (this.expanded || this.itemOnList) {
        if (this.itemOnList) {
          console.log({items: this.itemInstancesOnList})
          let total = calculate({
            items: this.itemInstancesOnList,
            currency: this.shopCartFrontend.currency,
          })
          console.log("total", total)
          let totalProducts = total.filter((i) => i.type == "products")
          let totalProductsAllVats = 0
          totalProducts.forEach((i) => (totalProductsAllVats += i.brutto))
          return Utils.formatPrice(totalProductsAllVats)
        } else {
          return Utils.formatPrice(this.price)
        }
      }
      // if product is closed, we show range
      else {
        return this.priceLabel
      }
    },
  },
  created() {
    this.selectedProduct = this.item
    this.selectedVariant = this.item
  },
  methods: {
    ec(element) {
      return this.elementClasses[element][this.expanded ? "expanded" : "contracted"]
    },
    onItemClick() {
      if (this.itemDetailDisplay == "page") {
        this.$router.push({
          name: `${this.itemDetailPageId}-${this.currentLang}`,
          params: {
            productSKU: this.itemSKU,
          },
        })
      } else if (this.itemDetailDisplay == "inline") {
        this.expanded = !this.expanded
      }
    },
    async addToList() {
      let count =
        this.selectedVariant.config.priceKind == "package"
          ? this.packageUserConfig.selectedCount
          : 1
      await this.shopShoppingListAdd({
        id: this.selectedVariant.id,
        count,
        snapshot: this.selectedVariant,
      })
      if (this.selectedVariant.config.priceKind == "voluntary") this.voluntaryPriceUpdated()
    },
    addMore() {
      console.log("addMore")
      let item = {}

      if (this.selectedVariant.config.priceKind == "package") {
        item = {
          id: this.selectedVariant.id,
          count: this.packageUserConfig.selectedCount,
          addAsNew: this.selectedVariant.config.priceKind == "package",
          snapshot: this.selectedVariant,
        }
      } else {
        item = {
          id: this.selectedVariant.id,
          count: 1,
        }
      }

      this.shopShoppingListAdd(item)
    },
    subtract() {
      let item = {}

      if (this.selectedVariant.config.priceKind == "package") {
        item = {
          id: this.selectedVariant.id,
          count: this.packageUserConfig.selectedCount,
          removeInstance: true,
        }
      } else {
        item = {
          id: this.selectedVariant.id,
          count: 1,
        }
      }

      this.shopShoppingListSubtract(item)
    },
    selectVariant(variant) {
      console.log("selectVariant()", variant)
      // if item on list, switch variant on the list
      if (this.itemOnList) {
        let currentItemOnList = this.itemOnList
        this.shopShoppingListRemove({
          id: this.selectedVariant.id,
        })
        this.shopShoppingListAdd({
          id: variant.id,
          count: currentItemOnList.count, // preserve count
          snapshot: variant,
        })
      }
      this.selectedVariant = variant
    },
    customerFormUpdated(v) {
      console.log("customerFormUpdated()", v, this.selectedVariant.config.customerForm)
      this.shopShoppingListUpdateSnapshot({
        id: this.selectedVariant.id,
        snapshot: this.selectedVariant,
      })
    },
    voluntaryPriceUpdated() {
      console.log("voluntaryPriceUpdated()", this.voluntaryPrice)
      let newSnapshot = this.selectedVariant

      let vp = this.voluntaryPrice.price
      let snapshotPrice = newSnapshot.price.find(
        (p) => p.currency == this.shopCartFrontend.currency && p.model == "voluntary"
      )
      if (!vp) vp = snapshotPrice.min

      snapshotPrice.netto = (parseInt(vp) / (100 + parseInt(snapshotPrice.vat_pct))) * 100
      snapshotPrice.brutto = parseInt(vp)

      newSnapshot.price = [snapshotPrice]

      this.shopShoppingListUpdateSnapshot({
        id: this.selectedVariant.id,
        snapshot: newSnapshot,
      })
    },
    variantUserOptionsUpdated() {
      console.log("variantUserOptionsUpdated()", this.variantUserOptions)
      let newSnapshot = this.selectedVariant
      newSnapshot.giftCardUserOptions = this.variantUserOptions
      this.shopShoppingListUpdateSnapshot({
        id: this.selectedVariant.id,
        snapshot: newSnapshot,
      })
    },
  },
}
</script>

<style scoped lang="less">
.paragraph {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>

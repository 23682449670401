<template>
  <div class="pb-4" :class="`text-left`">
    <OneLabel
      :text="Langs.get('delivery-method-constraints-label')"
      class="cursor-pointer"
      :class="{underline: showDeliveryMethodConstraints === false}"
      @click="showDeliveryMethodConstraints = !showDeliveryMethodConstraints"
      v-if="showLabel"
    />
    <div v-if="showDeliveryMethodConstraints || showLabel === false">
      <div class="mt-2" v-for="method in prod.config.shipping.methods" :key="method.id">
        <div>
          <span class="!font-bold">{{ getDeliveryMethodName(method) }}</span>

          <div
            v-if="
              method.constraints.minShippingMinEnabled || method.constraints.shippingDateFromEnabled
            "
          >
            <span class="block uppercase text-xs pt-2">{{
              Langs.get("delivery-method-constraints-shipping-from")
            }}</span>
            <p>
              {{ deliveryDispatchTime[method.id].string }}
            </p>
          </div>

          <div v-if="method.constraints.shippingDateToEnabled">
            <span class="block uppercase text-xs pt-2">{{
              Langs.get("delivery-method-constraints-shipping-to")
            }}</span>
            <p>{{ getDate(method.constraints.shippingDateTo).string }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Langs from "@/langs"
import moment from "moment"
import {useShopStore} from "@/stores/shop"
import {useSiteStore} from "@/stores/site"
import {storeToRefs} from "pinia"

export default {
  name: "DeliveryMethodConstraints",
  props: {
    product: {
      type: Object,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    align: {
      type: String,
      default: "center", // left | center | right
    },
  },
  setup() {
    // stores
    const shopStore = useShopStore()
    const siteStore = useSiteStore()

    // getters
    const {deliveryMethods: shopDeliveryMethods} = storeToRefs(shopStore)
    const {siteLangCurrent} = storeToRefs(siteStore)

    return {
      siteLangCurrent,
      shopDeliveryMethods,
    }
  },
  data: () => ({
    Langs,
    showDeliveryMethodConstraints: false,
    currentTime: new Date(),
  }),
  watch: {
    currentTime: {
      handler() {
        // // console.log(this.currentTime)
      },
    },
  },
  computed: {
    currentLang() {
      return this.siteLangCurrent
    },
    prod() {
      if (this.product.snapshot) return this.product.snapshot
      else return this.product
    },
    deliveryDispatchTime() {
      let methods = {}

      this.prod.config.shipping.methods.forEach((m) => {
        let minutes = m.constraints.minShippingMin
        let now = moment(this.currentTime)
        let date = moment(now).add(minutes, "minutes")

        if (
          m.constraints.shippingDateFromEnabled &&
          m.constraints.shippingDateFrom.toDate() > date
        ) {
          date = moment(m.constraints.shippingDateFrom.toDate())
        }

        methods[m.id] = {
          string: date.format("D. M. YYYY HH:mm"),
          date: date,
        }
      })

      return methods
    },
  },
  created() {
    this.updateCurrentTime()
  },
  methods: {
    updateCurrentTime() {
      this.currentTime = new Date()
      setTimeout(() => {
        this.updateCurrentTime()
      }, 1000 * 1)
    },
    getDate(date) {
      let d = date.toDate()
      return {
        string: moment(d).format("D. M. YYYY HH:mm"),
        date: d,
      }
    },
    getDeliveryMethodName(method) {
      let shopMethods = this.shopDeliveryMethods
      let shopMethod = shopMethods.find((m) => m.id == method.id)
      console.log("getDeliveryMethodName", method, shopMethods, shopMethod)
      return shopMethod && shopMethod.name ? shopMethod.name[this.currentLang] : ""
    },
  },
}
</script>

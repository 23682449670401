// these are used in every single block

export default {
  computed: {
    c() {
      return this.block && this.block._contentsData ? this.block._contentsData.content : false
    },
    viewportSize() {
      return this.$pinia?.state?.value?.app?.app?.viewport?.size
    },
    stylesConfig() {
      return this.block._contentsData ? this.block._contentsData.stylesConfig : false
    },
    sc() {
      if (this.stylesConfig) return this.stylesConfig
      if (this.block && this.block._contentsData) return this.block._contentsData.stylesConfig
      else return false
    },

    // inline styles that may be overriden by user settings
    styles() {
      let s = {}
      let elements = this.defaults.elements
      let sc = this.sc
      let vs = this.viewportSize
      // console.log('styles:', elements, sc, vs)
      if (!elements) return //console.error('mixinBlock:styles(): missing elements')
      if (!sc) return //console.error('mixinBlock:styles(): missing sc')
      if (!vs) return //console.error('mixinBlock:styles(): missing vs')
      Object.keys(elements).forEach((ek) => {
        // console.log('mixinBlock:styles(): element key:', ek)
        let el = elements[ek]
        if (!el) console.error("mixinBlock:styles(): el not found")
        Object.keys(el.styles).forEach((sk) => {
          // console.log('sk: ', el, ek, sk, s[ek])
          // set default styles
          if (!s[ek]) s[ek] = {}
          // create style key if it doesn't exist
          let val = ""
          // if there is no viewport config for current viewport, try universal
          if (!el.styles[sk][vs]) vs = "universal"
          // console.log('vs', vs, el.styles[sk])
          val += el.styles[sk][vs].value
          if (el.styles[sk][vs].unit) val += el.styles[sk][vs].unit
          s[ek][sk] = val

          let prop = sc && sc[ek] ? sc[ek][sk] : false
          if (prop && prop[vs] && prop[vs].value) {
            // user configured this style
            let v = typeof prop[vs] === "object" && prop[vs] !== null ? prop[vs] : prop
            if (v) {
              let val = ""
              val += v.value
              if (v.unit) val += v.unit
              s[ek][sk] = val
            }
          }

          // reset vs to original value
          vs = this.viewportSize
        })
      })
      return s
    },
  },
}

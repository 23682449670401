<template>
  <!-- variant 1 -->
  <OneFooter1 v-bind="{...$props, ...$attrs}" v-if="variant == 1" @clickLink="onClickLink" />

  <!-- variant 2 -->
  <OneFooter2 v-bind="{...$props, ...$attrs}" v-else-if="variant == 2" />
</template>

<script>
import mixinClasses from "../../mixins/classes"

export default {
  name: "OneFooter",
  mixins: [mixinClasses],
  props: {
    variant: {
      type: Number,
      default: 1,
    },
  },
  emits: ["clickLink"],
  methods: {
    onClickLink(link) {
      this.$emit("clickLink", link)
    },
  },
}
</script>

<template>
  <component class="one-element-heading" :is="`h${level}`" v-html="c" v-bind="props.root">
  </component>
</template>

<script>
import mixinClasses from "../../../mixins/classes"

export default {
  name: "oneHeading",
  mixins: [mixinClasses],
  props: {
    content: {
      type: [String, Number],
      default: "Heading",
    },
    level: {
      type: Number,
      _setsClass: true,
      default: 2,
      validator: (val) => [1, 2, 3, 4, 5, 6].includes(val),
    },
    align: {
      type: String,
      _setsClass: true,
      default: "left",
      validator: (val) => ["default", "left", "center", "right", "justify"].includes(val),
    },
    color: {
      type: String,
      _setsClass: true,
      default: "secondary",
      validator: (val) =>
        [
          "default",
          "primary-dark",
          "primary",
          "primary-light",
          "secondary-dark",
          "secondary",
          "secondary-light",
          "tertiary-dark",
          "tertiary",
          "tertiary-light",
          "white",
          "black",
        ].includes(val),
    },
    caps: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    defaults: {
      commonClasses: "",
      root: {
        class: `leading-tight font-bold tracking`,
        propClass: {
          align: {
            default: "text-left",
            left: "text-left",
            center: "text-center",
            right: "text-right",
            justify: "text-justify",
          },
          color: {
            default: "text-primary",
            primary: "text-primary",
            "primary-light": "text-primary-light",
            "primary-dark": "text-primary-dark",
            secondary: "text-secondary",
            "secondary-light": "text-secondary-light",
            "secondary-dark": "text-secondary-dark",
            tertiary: "text-tertiary",
            "tertiary-light": "text-tertiary-light",
            "tertiary-dark": "text-tertiary-dark",
            white: "!text-white",
            black: "!text-black",
          },
          level: {
            1: "font-h1 text-heading-h1 text-h1",
            2: "font-h2 text-heading-h2 text-h2",
            3: "font-h3 text-heading-h3 text-h3 !leading-[2rem]",
            4: "font-h4 text-heading-h4 text-h4 !leading-[1.25rem]",
            5: "font-h5 text-heading-h5 text-h5",
            6: "font-h6 text-heading-h6 text-h6",
          },
          caps: {
            true: "uppercase",
            false: "",
          },
        },
      },
    },
  }),
  computed: {
    c() {
      // force line breaks
      if (this.content && typeof this.content === "string")
        return this.content.replace("\n", "<br>")
      else return this.content
    },
  },
}
</script>

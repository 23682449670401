<template>
  <router-link
    :class="classes()"
    class="product-listing-item-grid flex flex-col justify-start transition-all duration-500 ease-in-out hover:opacity-70"
    :to="linkTo"
    @click="onProductItemClick"
  >
    <!-- image -->
    <div class="flex items-center justify-center">
      <p
        class="center absolute"
        v-if="display == 'unavailable' && soldOutAppearance && soldOutAppearance.imageLabelOverlay"
      >
        vyprodáno
      </p>
      <PlayIcon
        class="center absolute z-10 h-auto w-10 text-white"
        v-if="productKind == 'virtual' && productSubKind == 'video-on-demand'"
      />
      <OneImage
        :src="imageUrl"
        :imageId="imageId"
        kind="shop"
        :kindId="imageKindId"
        :grayscale="imageGrayscale"
        :ratio="imageRatio"
        :preload="true"
      ></OneImage>
    </div>

    <div class="align-center flex flex-1 flex-col justify-between">
      <!-- title -->
      <OneHeading
        :level="4"
        class="pt-2 !text-lg"
        align="left"
        :classOverride="this.defaultClasses.subcomponents.title"
        :content="itemTitle"
      />

      <!-- price -->
      <OneParagraph
        :classOverride="this.defaultClasses.subcomponents.price"
        align="left"
        color="primary"
        :content="priceLabel"
      />
    </div>
  </router-link>
</template>

<script>
import {useClasses} from "@/composables/classes.js"
import {useSiteStore} from "@/stores/site"
import {storeToRefs} from "pinia"
import {PlayIcon} from "@heroicons/vue/24/solid"
import {event} from "@/sink"

export default {
  name: "ProductListingItemGrid",
  components: {
    PlayIcon,
  },
  props: {
    itemDetailPageId: String,
    itemId: String,
    itemSKU: String,
    display: String,
    soldOutAppearance: Object,
    imageUrl: [String, Boolean],
    imageId: [String, Boolean],
    imageKindId: String,
    imageGrayscale: [String, Boolean],
    imageRatio: String,
    itemTitle: String,
    priceLabel: String,
    productKind: String,
    productSubKind: String,
  },
  setup(props) {
    const Classes = useClasses({props})
    const {classes} = Classes

    // stores
    const siteStore = useSiteStore()

    const {sitePlatform, siteLangCurrent} = storeToRefs(siteStore)

    return {
      sitePlatform,
      siteLangCurrent,
      classes,
    }
  },
  data: () => ({
    defaultClasses: {
      base: "grid grid-cols-1 justify-center hover:opacity-70 transition-all duration-500 ease-in-out",
      props: {},
      subcomponents: {
        image: {
          base: "+ min-h-full",
        },
        title: {
          base: "font-primary break-word leading-tight font-bold text-center pt-2",
          props: {
            level: {
              3: "text-base",
            },
          },
        },
        price: {
          base: "+ font-secondary text-center pt-1 tracking-tight",
        },
      },
    },
  }),
  computed: {
    currentLang() {
      return this.siteLangCurrent
    },
    linkTo() {
      console.log("platform:", this.sitePlatform)
      if (["qasima.ae", "qasima.cz"].includes(this.sitePlatform)) {
        console.log("route to lang:", this.$route.params.lang)
        return {
          name: `${this.itemDetailPageId}`,
          params: {productSKU: this.itemSKU, lang: this.$route.params.lang},
        }
      } else {
        return {
          name: `${this.itemDetailPageId}-${this.currentLang}`,
          params: {productSKU: this.itemSKU},
        }
      }
    },
  },
  methods: {
    onProductItemClick(e) {
      event("ProductView", {
        itemId: this.itemId,
        itemSku: this.itemSKU,
      })
    },
  },
}
</script>

<style scoped lang="less"></style>

<template>
  <!-- filter -->
  <OneContainer class="" verticalPadding="0" v-if="filteringEnabled">
    <div class="flex flex-row justify-end w-full">
      <OneIcon icon="Filter" @update:click="drawerOpen = !drawerOpen" link />
    </div>

    <!-- drawer -->
    <div
      class="fixed bottom-0 left-0 right-0 flex flex-row justify-center z-50"
      v-show="drawerOpen"
    >
      <div class="filter-drawer bg-white rounded-t-3xl p-4 max-w-6xl">
        <div class="relative flex justify-center">
          <OneIcon icon="DrawerHide" color="500" link @update:click="drawerOpen = false" />
          <OneIcon
            class="absolute top-0 right-0 !w-10"
            icon="FilterDisable"
            color="danger"
            @update:click="clearFilter()"
            link
          />
        </div>

        <div
          class="filters overflow-y-scroll"
          v-if="shopItemLabelDataLoaded && shopItemLabelGroupsDeep"
        >
          <div v-for="group in shopItemLabelGroupsDeep" :key="group.id">
            <span v-if="showGroupTitles">{{ group.title[siteLangCurrent] }}</span>
            <div v-for="label in group.labels" :key="label.id">
              <span class="pt-4 pb-2 block" v-if="label && label.title">{{
                label.title[siteLangCurrent]
              }}</span>

              <OneFormChips
                label=""
                :value="labelChipsValuesSelected"
                :chips="getLabelChipsValues(label.values)"
                @update:modelValue="onChipsInput($event, label.id)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </OneContainer>
</template>

<script>
import mixinBlock from "@/mixins/block"
import Langs from "@/langs"
import {useShopItemLabelGroupsStore} from "@/stores/shopItemLabelGroups"
import {useShopItemLabelsStore} from "@/stores/shopItemLabels.js"
import {useShopItemLabelValuesStore} from "@/stores/shopItemLabelValues.js"
import {useSiteStore} from "@/stores/site"
import {storeToRefs} from "pinia"
import {computed} from "vue"

export default {
  name: "ProductListingFilter",
  mixins: [mixinBlock],
  props: {
    products: {
      type: Array,
    },
    block: {
      type: Object,
    },
  },
  emits: ["update:modelValue"],
  setup() {
    // stores
    const shopItemLabelGroupsStore = useShopItemLabelGroupsStore()
    const shopItemLabelsStore = useShopItemLabelsStore()
    const shopItemLabelValuesStore = useShopItemLabelValuesStore()
    const siteStore = useSiteStore()

    // states
    const {groups: shopItemLabelGroups} = storeToRefs(shopItemLabelGroupsStore)
    const {labels: shopItemLabels} = storeToRefs(shopItemLabelsStore)
    const {values: shopItemLabelValues} = storeToRefs(shopItemLabelValuesStore)

    // getters
    const {siteLangCurrent} = storeToRefs(siteStore)
    const {shopItemLabelGroupsLoaded} = storeToRefs(shopItemLabelGroupsStore)
    const {shopItemLabelValuesLoaded} = storeToRefs(shopItemLabelValuesStore)
    const {shopItemLabelsLoaded} = storeToRefs(shopItemLabelsStore)

    // actions
    const {bind: shopItemLabelGroupsStoreBind} = shopItemLabelGroupsStore
    const {bind: shopItemLabelsStoreBind} = shopItemLabelsStore
    const {bind: shopItemLabelValuesStoreBind} = shopItemLabelValuesStore

    const shopItemLabelDataLoaded = computed(
      () => shopItemLabelGroupsLoaded && shopItemLabelValuesLoaded && shopItemLabelsLoaded
    )

    // reconstruct deep references (previously handled by vuex)
    const shopItemLabelGroupsDeep = computed(() => {
      if (!shopItemLabelDataLoaded.value) return []
      let silg = JSON.parse(JSON.stringify(shopItemLabelGroups.value))
      // // console.log("silg", silg)
      silg.forEach((group, groupIndex) => {
        // console.log("silg group, index", group, groupIndex)

        if (group?.labels) {
          group.labels.forEach((label, labelIndex) => {
            if (label && label.length) {
              const labelId = label.split("/")[3]
              const labelData = shopItemLabels.value.find((i) => i.id == labelId)

              if (labelData?.values) {
                silg[groupIndex].labels[labelIndex] = labelData
                labelData.values.forEach((value, valueIndex) => {
                  if (value && value.length) {
                    const valueId = value.split("/")[5]
                    const valueData = shopItemLabelValues.value.find((i) => i.id == valueId)
                    silg[groupIndex].labels[labelIndex].values[valueIndex] = valueData
                  }
                })
              }
            }
          })
        }
      })
      return silg
    })

    return {
      siteLangCurrent,
      shopItemLabelGroupsStoreBind,
      shopItemLabelsStoreBind,
      shopItemLabelValuesStoreBind,
      shopItemLabelGroups,
      shopItemLabels,
      shopItemLabelDataLoaded,
      shopItemLabelGroupsDeep,
    }
  },
  data: () => ({
    Langs,
    filteringEnabled: true,
    drawerOpen: false,
    showGroupTitles: false, // TODO: configurable
    selectedFilters: {},
    labelChipsValuesSelected: [],
  }),
  created() {
    this.load()
  },
  methods: {
    load() {
      if (this.block.config.filtersLabelGroups && this.block.config.filtersLabelGroups.length) {
        this.shopItemLabelGroupsStoreBind({
          filter: this.block.config.filtersLabelGroups,
        })
        this.shopItemLabelValuesStoreBind()
        this.shopItemLabelsStoreBind()
      } else {
        console.error("no label groups selected")
      }
    },
    onChipsInput(labelValue, labelId) {
      let valueId = labelValue.value
      // exists
      if (
        this.selectedFilters[labelId] &&
        this.selectedFilters[labelId].findIndex((v) => v === valueId) !== -1
      ) {
        // console.log('exists, removing')
        let index = this.selectedFilters[labelId].findIndex((v) => v === valueId)
        this.selectedFilters[labelId].splice(index, 1)
        if (this.selectedFilters[labelId].length === 0) delete this.selectedFilters[labelId]
      } else {
        // console.log('doesnt exist, adding')
        // adding
        if (!this.selectedFilters[labelId]) this.selectedFilters[labelId] = []
        this.selectedFilters[labelId].push(valueId)
      }
      this.$emit("update:modelValue", this.selectedFilters)
    },
    getLabelChipsValues(values) {
      if (!values) return
      return values.map((v) => {
        return {
          value: v.id,
          title: v.title ? v.title[this.siteLangCurrent] : "",
        }
      })
    },
    selectValue(labelId, valueId) {
      // exists
      if (
        this.selectedFilters[labelId] &&
        this.selectedFilters[labelId].findIndex((v) => v === valueId) !== -1
      ) {
        // console.log('exists, removing')
        let index = this.selectedFilters[labelId].findIndex((v) => v === valueId)
        this.selectedFilters[labelId].splice(index, 1)
        if (this.selectedFilters[labelId].length === 0) delete this.selectedFilters[labelId]
      } else {
        // console.log('doesnt exist, adding')
        // adding
        if (!this.selectedFilters[labelId]) this.selectedFilters[labelId] = []
        this.selectedFilters[labelId].push(valueId)
      }
      this.$emit("update:modelValue", this.selectedFilters)
    },
    labelActive(labelId, valueId) {
      if (
        this.selectedFilters[labelId] &&
        this.selectedFilters[labelId].findIndex((v) => v === valueId) !== -1
      ) {
        return true
      } else {
        return false
      }
    },
    clearFilter() {
      this.selectedFilters = {}
      this.labelChipsValuesSelected = []
      this.$emit("update:modelValue", this.selectedFilters)
    },
  },
}
</script>

<style scoped lang="less">
.filter-drawer {
  // max-height: 80vh;
  box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.15);
}

.filters {
  max-height: 70vh;
}
</style>

import zenscroll from "zenscroll"
import {format} from "@jirizavadil/one-utils"
import {useShopStore} from "@/stores/shop"
import {useShopCartStore} from "@/stores/shopCart"

zenscroll.setup(null, 100) // offset top fixed banner

window.zenscroll = zenscroll

export default {
  /**
   *
   * @param  {} args.targetElementId
   * @return {}
   */
  scrollTo(args) {
    var defaultDuration = 500
    var edgeOffset = 100
    var cartMain = document.getElementById("cart-window")
    var cartMainScroller = zenscroll.createScroller(cartMain, defaultDuration, edgeOffset)

    if (args.targetElementId) {
      setTimeout(() => {
        if (document.getElementById(args.targetElementId)) {
          cartMainScroller.to(document.getElementById(args.targetElementId), 1000)
        }
      }, 200)
    }
  },

  formatNumber(value, locale) {
    let l = locale ? locale : navigator.language

    return format.number(value, l)
  },

  formatPrice(value, currency) {
    const shopStore = useShopStore()
    const shopCartStore = useShopCartStore()

    // currency code
    let cc = currency
      ? currency
      : shopCartStore.frontend.currency
      ? shopCartStore.frontend.currency
      : shopStore.shop.config?.defaultCurrency
      ? shopStore.shop.config.defaultCurrency
      : "CZK" // fallback if nothing is loaded (yet)

    return format.price(value, cc)
  },
}

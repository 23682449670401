<template>
  <div
    class="w-full text-center md:text-left mb-6"
    :class="{'flex flex-row justify-between items-center': layout == 'horizontal'}"
  >
    <!-- <OneHeading :level="4" align="center" :content="Langs.get('pricePackage-price')" /> -->

    <OneLabel text="počet" />

    <div class="relative">
      <OneLoading class="absolute inset-0 z-10" v-if="checkingStock" />
      <OneFormRadio
        v-model="modelValue.selectedCount"
        :options="countOptions"
        layout="grid"
        :columns="5"
      />
    </div>
  </div>
</template>

<script>
import Langs from "@/langs"
import Utils from "@/utils"
import {functions} from "@/firebase/index.js"
import {httpsCallable} from "firebase/functions"
import maxBy from "lodash/maxBy"
import {useSiteStore} from "@/stores/site"
import {storeToRefs} from "pinia"

const checkStockFn = httpsCallable(functions, "calls-commerce-cart-checkStock")

export default {
  name: "PricePackage",
  props: {
    modelValue: {
      type: Object,
    },
    selectedVariant: {
      type: Object,
    },
    layout: {
      type: String,
      default: "vertical", // horizontal | vertical
    },
  },
  setup() {
    // stores
    const siteStore = useSiteStore()

    // states
    const {siteVar} = storeToRefs(siteStore)

    return {
      siteVar,
    }
  },
  data: () => ({
    Langs,
    checkingStock: true,
    maxUnits: 0,
    maxAvailable: 0,
  }),
  computed: {
    counts() {
      return this.selectedVariant.price.filter((p) => p.model == "package")
    },
    countOptions() {
      let options = []
      this.counts.forEach((o) => {
        let disabled = false
        if (this.trackingEnabled && (this.checkingStock || o.units > this.maxAvailable))
          disabled = true

        options.push({
          name: Utils.formatNumber(o.units),
          value: o.units,
          disabled,
        })
      })
      return options
    },
    trackingEnabled() {
      return this.selectedVariant.config.quantity.stock.trackingEnabled
    },
  },
  mounted() {
    // select first price on load
    this.modelValue.selectedCount = this.counts[0].units
    if (this.trackingEnabled) {
      this.checkStock()
      this.setMaxUnits()
    } else {
      this.checkingStock = false
    }
  },
  methods: {
    setMaxUnits() {
      this.maxUnits = maxBy(this.counts, (o) => parseFloat(o.units)).units
    },
    async checkStock() {
      let shopId = this.siteVar.shopId
      let itemId = this.selectedVariant.id
      let req = {
        shopId,
        itemId,
        requestedCount: this.maxUnits,
      }
      console.log("checkStock()", req)
      let response = await checkStockFn(req)
      console.log("reponse", response)
      this.maxAvailable = response.data.availableCount
      this.checkingStock = false
    },
  },
}
</script>

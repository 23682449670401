<template>
  <!-- do not add overflow-hidden here - it will cutoff selects that are on the bottom edge -->
  <div class="bg-neutral-200 dark:bg-neutral-950 rounded-xl p-2">
    <OneLabel :text="label" v-if="label" />
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
  },
}
</script>

<template>
  <div
    class="one-element-form-tags"
    :class="[{disabled: disabled}, `mode-${mode}`, `size-${size}`]"
  >
    <OneLabel :text="label" />

    <div class="tags-wrap">
      <!-- tags -->
      <div class="tag" v-for="(tag, index) in modelValue" :key="tag.value">
        <div class="tag-content">
          <span>{{ getTitle(tag) }}</span>
        </div>
        <div class="tag-actions" v-if="mode == 'edit'">
          <span @click="remove(index)">
            <OneIcon class="" icon="Times" key="Times" />
          </span>
        </div>
      </div>

      <!-- new tag input field -->
      <OneDatatypeString
        v-model="newTag"
        ref="newTagInput"
        class="datatype-string"
        :key="newTagKey"
        @update:keyup="onKeyUp"
        @update:modelValue="processInput"
        @update:focus="onFocus"
        @update:blur="onBlur"
        v-if="mode == 'edit'"
      />
    </div>

    <!-- autocomplete -->
    <div
      class="autocomplete-wrap"
      :class="[
        {'layout-horizontal-scroll': autocompleteLayout == 'horizontalScroll'},
        {'layout-vertical-wrap': autocompleteLayout == 'verticalWrap'},
      ]"
      v-if="showAutocomplete && mode == 'edit'"
    >
      <div class="items-wrap">
        <div class="items">
          <div class="tag" v-for="(item, index) in autocompleteItems" :key="index">
            <div class="tag-content" @click="insertAutocomplete(item)">
              <span>{{ item.title }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="actions">
        <OneIcon
          class=""
          icon="XCircle"
          key="XCircle"
          size="small"
          link
          @update:click="showAutocomplete = false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {nextTick} from "vue"

export default {
  name: "OneFormTags",
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: [Array, Boolean],
      default: false,
    },
    autocompleteLayout: {
      type: String,
      default: "horizontalScroll", // horizontalScroll | verticalWrap
    },
    size: {
      type: String,
      default: "default", // small | default
    },
    mode: {
      type: String,
      default: "edit",
    },
  },
  emits: ["update:modelValue", "added"],
  data() {
    return {
      showAutocomplete: false,
      newTag: "",
      newTagKey: 1,
      ignoreNextProcessInput: false,
    }
  },
  computed: {
    // filter out used values
    autocompleteItems() {
      if (!this.autocomplete) return
      let items = []
      this.autocomplete.forEach((i) => {
        if (!this.modelValue.find((v) => v.value == i.value)) {
          items.push(i)
        }
      })
      if (items.length === 0) {
        // no idea what is the issue here
        /* eslint-disable-next-line */
        this.showAutocomplete = false
      }
      return items
    },
  },
  methods: {
    getTitle(tag) {
      if (tag.title) return tag.title
      else if (tag.value) return tag.value
      else if (this.autocomplete && this.autocomplete.find((t) => t.value == tag))
        return this.autocomplete.find((t) => t.value == tag).title
      else return tag
    },
    onFocus() {
      if (this.autocompleteItems) this.showAutocomplete = true
    },
    onBlur() {
      //   if (this.autocompleteItems) this.showAutocomplete = false
    },
    onKeyUp(e) {
      // console.log('onKeyUp():', e)
      // on space / comma
      // if ([32, 188].includes(e.keyCode)) {
      if ([188].includes(e.keyCode)) {
        this.ignoreNextProcessInput = true
        this.createTag()
      }
      // enter - because Enter doesn't trigger onInput event
      if ([13].includes(e.keyCode)) {
        this.createTag()
        this.clearNewTag()
      }
    },
    processInput(v) {
      if (!this.ignoreNextProcessInput) {
        // console.log('processInput(): updating tag', v)
        this.newTag = v.trim()
      } else {
        this.clearNewTag()
        this.ignoreNextProcessInput = false
      }
    },
    clearNewTag() {
      // console.log('processInput(): clearing tag')
      this.newTag = ""
      this.newTagKey = this.newTagKey + 1 // this is to force Vue to reset DatatypeString component
      nextTick(() => {
        this.$refs.newTagInput.$el.focus()
      })
    },
    insertAutocomplete(item) {
      let tag = item.value ? item.value : item
      this.modelValue.push(tag)
      console.log('insertAutocomplete():', tag, this.modelValue)
      this.$emit("update:modelValue", this.modelValue)
    },
    createTag() {
      let tag = this.newTag.trim()
      console.log("createTag():", tag, tag.length)

      if (tag.length == 0) return

      // this.modelValue.push({
      //   title: tag,
      //   value: tag,
      // })
      // this.$emit("update:modelValue", this.modelValue)
      this.$emit("added", tag)
    },
    remove(index) {
      console.log("remove", index, this.modelValue)
      this.modelValue.splice(index, 1)
      this.$emit("update:modelValue", this.modelValue)
    },
  },
}
</script>

<style scoped lang="less">
@import "../../../variables.less";

.one-element-form-tags {
  &.mode-view {
    .tags-wrap {
      border: none;
      padding: 0;

      .tag {
        &:first-child {
          margin-left: 0;
        }

        .tag-content {
          padding: 10px 12px 10px 12px;
        }
      }
    }
  }

  &.size-small {
    .tags-wrap {
      .tag {
        margin: 2px;
        border-radius: 5px;

        .tag-content {
          padding: 3px 4px;

          span {
            font-size: 0.75rem;
          }
        }
      }
    }
  }

  span.label {
    padding-left: 8px;
    color: @pfm-color-neutral-600;
  }

  .tags-wrap,
  .autocomplete-wrap {
    margin-bottom: 10px;
    border: 2px solid @pfm-color-neutral-400;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 5px;

    &:hover {
      background: @pfm-color-neutral-100;
    }

    .tag {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background: @pfm-color-neutral-700;
      border-radius: 8px;
      margin: 5px;

      .tag-content {
        padding: 10px 0 10px 12px;
        span {
          color: @pfm-color-neutral-200;
          font-size: 0.9rem;
          line-height: 0.9rem;
          display: block;
          cursor: default;
        }
      }
      .tag-actions {
        span {
          display: flex;
          padding: 0 4px;
          cursor: pointer;

          &:hover {
            :deep(svg) {
              * {
                fill: @pfm-color-neutral-200;
              }
            }
          }

          :deep(svg) {
            width: 24px;
            height: 24px;

            * {
              fill: @pfm-color-neutral-400;
            }
          }
        }
      }
    }

    .datatype-string {
      flex: 1;
      min-width: 50px;
      // outline: 1px solid #ff0000;
    }
  }

  .autocomplete-wrap {
    background: @pfm-color-neutral-400;
    border-color: @pfm-color-neutral-400;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &:hover {
      background: @pfm-color-neutral-400;
    }

    &.layout-vertical-wrap {
      align-items: flex-start;
      .items-wrap {
        .items {
          flex-wrap: wrap;
          width: 100%;
        }
      }
    }

    .items-wrap {
      overflow-x: scroll;
      flex: 1;

      .items {
        display: flex;
        flex-direction: row;
        width: 0;

        .tag {
          cursor: pointer !important;
          transition: all 0.25s ease-in-out;

          &:hover {
            background: @pfm-color-neutral-600;
          }

          .tag-content {
            white-space: nowrap;
            padding: 10px 12px;

            span {
              cursor: pointer !important;
            }
          }
        }
      }
    }

    .actions {
      margin-left: 5px;
    }
  }
}
</style>

import {ref} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"

export const useShopShoppingListStore = defineStore("shopShoppingList", () => {
  // state
  const items = ref([])

  // getters

  // actions
  function add(args) {
    // if already on list, increase count
    const itemOnList = items.value.find((i) => i.id == args.id)
    const count = args.count ? args.count : 1
    if (itemOnList && !args.addAsNew) {
      const newCount = itemOnList.count + count
      itemSetCount(args.id, newCount)
    } else {
      items.value.push(args)
    }
  }

  function subtract(args) {
    let itemOnList = items.value.find((i) => i.id == args.id)
    let count = args.count ? args.count : 1
    if (itemOnList) {
      if (itemOnList.count > 1 && !args.removeInstance) {
        const newCount = itemOnList.count - count
        itemSetCount(args.id, newCount)
      } else {
        itemRemove(args.id)
      }
    }
  }

  function remove(args) {
    itemRemove(args.id)
  }

  function clear() {
    items.value = []
  }

  function updateSnapshot(args) {
    const itemOnList = items.value.find((i) => i.id == args.id)
    if (itemOnList) {
      const itemIndex = items.value.findIndex((i) => i.id == args.id)
      items.value[itemIndex].snapshot = args.snapshot
    }
  }

  // shared non public
  function itemSetCount(itemId, count) {
    const itemIndex = items.value.findIndex((i) => i.id == itemId)
    items.value[itemIndex].count = count
  }

  function itemRemove(itemId) {
    const itemIndex = items.value.findIndex((i) => i.id == itemId)
    if (itemIndex != -1) items.value.splice(itemIndex, 1)
  }

  return {
    // state
    items,

    // getters

    // actions
    add,
    subtract,
    remove,
    clear,
    updateSnapshot,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useShopShoppingListStore, import.meta.hot))
}

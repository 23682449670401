<template>
  <div :data-one-component="$options.name" v-bind="props.root">
    <!-- content -->
    <slot />

    <!-- background wrap -->
    <div
      data-one-label="backgroundWrap"
      v-bind="props.backgroundWrap"
      v-if="backgroundImage && backgroundImage.src"
    >
      <!-- background image -->
      <!-- :style="`clip-path: url(#svg-clip-${componentUid})`" -->
      <OneImage
        v-bind="props.backgroundImage"
        :src="backgroundImage.src"
        :imageId="backgroundImage.imageId"
        :fit="backgroundFit"
        key="bgImage"
        v-if="backgroundKind == 'image'"
      />

      <!-- slideshow -->
      <OneImageSlideshow
        v-bind="props.backgroundImage"
        :fit="backgroundFit"
        :images="backgroundSlideshow"
        :config="backgroundSlideshowConfig"
        v-if="backgroundKind == 'slideshow'"
      />

      <!-- background video -->
      <OneVideo
        v-bind="props.backgroundVideo"
        :src="backgroundVideo.src"
        :videoId="backgroundVideo.videoId"
        :contentType="backgroundVideo.contentType"
        :posterSrc="backgroundVideoPoster.src"
        :fit="backgroundFit"
        key="bgVideo"
        v-if="backgroundKind == 'video'"
      />

      <!-- svg blob clip mask -->
      <!-- <svg
      class="svg-clip-container"
      width="0"
      height="0"
      xmlns="http:/www.w3.org/2000/svg"
    >
      <defs>
        <clipPath :id="`svg-clip-${componentUid}`">
          <path stroke="none" stroke-width="0" fill="black" :d="svgPath"></path>
        </clipPath>
      </defs>
    </svg> -->
    </div>
  </div>
</template>

<script>
import mixinClasses from "../../mixins/classes"
// import * as blobs2 from "blobs/v2"
import {v4 as uuidv4} from "uuid"
export default {
  name: "OneContainer",
  mixins: [mixinClasses],
  props: {
    stylesConfig: {
      type: [Boolean, Object],
    },
    backgroundKind: {
      type: String,
    },
    backgroundImage: {
      type: [Boolean, Object],
    },
    backgroundSlideshow: {
      type: [Boolean, Array],
    },
    backgroundSlideshowConfig: {
      type: [Boolean, Object],
    },
    backgroundVideo: {
      type: [Boolean, Object],
    },
    backgroundVideoPoster: {
      type: [Boolean, Object],
    },
    backgroundFit: {
      type: String,
      default: "cover",
    },
    relative: {
      type: Boolean,
      default: false,
    },
    contentLayout: {
      type: String,
      default: "flex", // flex | grid
    },
    contentAlign: {
      type: String,
      default: "center",
    },
    width: {
      type: String,
      default: "constrained", // full | constrained
    },
    height: {
      type: String,
      default: "auto", // auto | full | screen
    },
    verticalPadding: {
      type: [String, Number],
      default: 5,
    },
  },
  setup() {
    const componentUid = uuidv4()

    return {
      componentUid,
    }
  },
  data: () => ({
    svgPath: "",
    defaults: {
      commonClasses: "",
      root: {
        class: "thisiscontainer",
        propClass: {
          contentLayout: {
            flex: "flex",
            grid: "grid",
          },
          contentAlign: {
            none: "",
            _common: "",
            topLeft: "flex-top-left",
            topCenter: "flex-top-center",
            topRight: "flex-top-right",
            middleLeft: "flex-middle-left",
            center: "flex-center",
            middleRight: "flex-middle-right",
            bottomLeft: "flex-bottom-left",
            bottomCenter: "flex-bottom-center",
            bottomRight: "flex-bottom-right",
            spaceBetween: "flex justify-between align-center",
          },
          relative: {
            // true: 'relative',
            // false: 'm-auto px-2 xs:px-4 sm:px-6 xl:px-32 py-0'
          },
          width: {
            none: "",
            full: "px-0",
            // constrained: "w-full m-auto px-2 xs:px-4 sm:px-6 xl:px-32 py-0",
            constrained: "m-auto max-w-7xl px-4 2xl:px-0",
          },
          height: {
            none: "",
            auto: "h-auto",
            full: "h-full",
            screen: "h-screen",
          },
          verticalPadding: {
            0: "py-0",
            5: "py-5",
          },
        },
      },
      backgroundWrap: {
        class: "absolute inset-0 z-0 flex-center",
      },
      backgroundImage: {
        maxHeight: "screen",
        minHeight: "full",
        // propClass: {
        //   height: {
        //     screen: 'max-h-screen'
        //   }
        // }
      },
      backgroundVideo: {
        maxHeight: "screen",
        minHeight: "full",
      },
    },
  }),
}
</script>

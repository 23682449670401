<template>
  <section class="page" :id="`page_${$route.meta.id}`">
    <!-- admin: block selector -->
    <BlockSelector :pageId="this.page.id" v-if="admin.addingBlock" />

    <!-- admin: add first block -->
    <div class="add-first-block-wrap" v-if="appMode == 'admin' && blocks.length == 0">
      <div class="add-first-block" @click="addFirstBlock()">
        <a class="add-first-block-button" href="#">
          <OneIcon icon="Plus" class="children:fill-white" />
        </a>
      </div>
    </div>

    <!-- page blocks -->
    <div v-for="(block, index) in blocks" :key="`${block.id}-${index}`">
      <BlockNative
        :block="block"
        :blockIndex="index"
        :last="index == blocks.length - 1"
        :pageId="page.id"
        v-if="allowRender(block)"
      />
    </div>

    <!-- platforma.one footer -->
    <div class="w-full p-2 text-center text-xs font-light text-neutral-500" v-if="showFooter">
      <a href="https://qasima.ae" target="_blank" v-if="siteVar?.global?.platform === 'qasima.ae'"
        >powered by qasima.ae</a
      >
      <a
        href="https://qasima.cz"
        target="_blank"
        v-else-if="siteVar?.global?.platform === 'qasima.cz'"
        >powered by qasima.ae</a
      >
      <a href="https://platforma.one" target="_blank" v-else>powered by platforma.one</a>
    </div>
  </section>
</template>

<script>
import BlockSelector from "@/components/BlockSelector.vue"
import BlockNative from "@/components/BlockNative.vue"
import {useAdminStore} from "@/stores/admin"
import {useAppStore} from "@/stores/app"
import {useShopItemsStore} from "@/stores/shopItems"
import {useSiteStore} from "@/stores/site"
import {storeToRefs} from "pinia"

export default {
  name: "Page",
  components: {
    BlockSelector,
    BlockNative,
  },
  setup() {
    // stores
    const adminStore = useAdminStore()
    const appStore = useAppStore()
    const shopItemsStore = useShopItemsStore()
    const siteStore = useSiteStore()

    // states
    const {app} = storeToRefs(appStore)
    const {admin} = storeToRefs(adminStore)

    // getters
    const {mode: appMode} = storeToRefs(appStore)
    const {selectedItemVariant: shopItemsSelectedItemVariant} = storeToRefs(shopItemsStore)
    const {siteVar, pages: sitePages, hasShop: siteHasShop} = storeToRefs(siteStore)

    // actions
    const {addBlock: adminAddBlock} = adminStore

    return {
      app,
      appMode,
      admin,
      adminAddBlock,
      siteVar,
      sitePages,
      siteHasShop,
      shopItemsSelectedItemVariant,
    }
  },
  data: () => ({
    showFooter: false,
    page: {
      id: null,
      index: null,
      config: null,
    },
  }),
  computed: {
    blocks() {
      let blocks = this.sitePages[this.page.index].blocks
      return blocks.map((b) => {
        if (this.siteHasShop && b.config?.contentFromItemDetail) {
          console.log("block requires content from product detail")

          if (this.itemHasContentForBlock(b.id)) {
            console.log("product has content for block")
            b._contentsData.content = this.selectedVariant.dynamicSections[b.id].content
          }
        }
        return b
      })
    },
    selectedVariant() {
      if (!this.siteHasShop) return
      return this.shopItemsSelectedItemVariant
    },
    pageHasHero() {
      return this.blocks.some((b) => b.nativeBlock == "main/hero")
    },
    heroIsRendered() {
      return this.app.heroIsRendered
    },
  },
  created() {
    this.page.id = this.$route.meta.id
    // console.log('Page component loaded', this.$route)
    this.setPageDetails()
    setTimeout(() => {
      this.showFooter = true
    }, 250)
  },
  updated() {
    this.setPageDetails()
  },
  mounted() {
    if (this.displayScatteredImages) {
      this.randomScatterImages()
    }
  },
  methods: {
    itemHasContentForBlock(blockId) {
      return this.selectedVariant &&
        this.selectedVariant.dynamicSections &&
        this.selectedVariant.dynamicSections[blockId] &&
        this.selectedVariant.dynamicSections[blockId].content
        ? true
        : false
    },
    addFirstBlock() {
      if (this.appMode != "admin") return
      // console.log('addFirstBlock()')

      this.adminAddBlock({
        referenceBlockIndex: 0,
      })
    },
    setPageDetails() {
      // console.log('getPageIndex')
      this.page.index = this.sitePages.findIndex((page) => page.id == this.$route.meta.id)
      this.page.config = this.sitePages[this.page.index].config
    },
    getBlockKind(block) {
      // console.log('getBlockKind()', block.nativeBlock)
      if (block.nativeBlock) {
        return "native"
      } else {
        return "legacy"
      }
    },
    allowRender(block) {
      // if dynamic block has no content provided from product, don't render it
      if (
        this.appMode != "admin" &&
        block.config.contentFromItemDetail &&
        this.itemHasContentForBlock(block.id) === false
      )
        return false

      if (!this.pageHasHero || (this.pageHasHero && this.heroIsRendered)) return true
      // hero is always allowed, because it's needed first
      if (block.nativeBlock == "main/hero") return true
      else return false
    },
  },
}
</script>

<style lang="less" scoped>
@import "../variables.less";

#scattered-images-canvas {
  height: 100vh;
  width: 100vw;
  position: fixed;

  .image {
    position: absolute;
    z-index: 2;
  }
}

section.page {
  .add-first-block-wrap {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .add-first-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // border: 4px dashed @pfm-color-neutral-400;
      padding: 60px 80px;
      border-radius: 12px;
      background-color: @pfm-color-neutral-200;
      z-index: 8888;

      &:hover {
        background-color: @pfm-color-neutral-300;
        cursor: pointer;

        a.add-first-block-button {
          background-color: @pfm-color-neutral-700;
        }
      }

      a.add-first-block-button {
        background-color: @pfm-color-neutral-900;
        border-radius: 50%;
        transition: background-color 0.25s ease-in-out;
        padding: 10px;

        // &:hover {
        //   background-color: @pfm-color-neutral-700;
        // }

        svg {
          width: 32px;
          height: 32px;

          * {
            fill: #fff;
          }
        }
      }

      span {
        margin-top: 20px;
        font-size: 1.5rem;
        font-weight: bold;
      }
    }
  }
}
</style>

// these are shared in:
// - components/blocks/commerce/ProductDetail.vue
// - components/elements/commerce/ProductListingItemList.vue

export default {
  data: () => ({
    variantUserOptions: {
      dispatch: null,
      recipient: {
        name: "",
        email: "",
      },
      sender: {
        name: "",
        email: "",
      },
      giftCardImage: {
        id: null,
      },
    },
    voluntaryPrice: {
      price: null,
    },
    packageUserConfig: {
      selectedCount: null,
    },
  }),
  computed: {
    showDeliveryMethodConstraints() {
      if (this.display == "preorder") return true
      if (this.display == "unavailable") return false
      let someConstraintEnabled = false
      if (!this.selectedVariant.config.shipping.methods) return false
      this.selectedVariant.config.shipping.methods.forEach((m) => {
        if (
          m &&
          m.constraints &&
          (m.constraints.minShippingMinEnabled ||
            m.constraints.shippingDateFromEnabled ||
            m.constraints.shippingDateToEnabled)
        )
          someConstraintEnabled = true
      })
      return someConstraintEnabled
    },
    isGiftCard() {
      return (
        this.selectedVariant.config.productKind == "virtual" &&
        this.selectedVariant.config.productSubKind == "giftCard"
      )
    },
    isBooking() {
      return (
        this.selectedVariant.config.productKind == "virtual" &&
        this.selectedVariant.config.productSubKind == "booking"
      )
    },
    hasVariants() {
      return this.selectedProduct.variants && this.selectedProduct.variants.length > 0
    },
    showVariantsSwitch() {
      return (
        this.hasVariants ||
        (this.selectedVariant.attribs && Object.keys(this.selectedVariant.attribs).length > 0)
      )
    },
    price() {
      // console.log("price()", this.shopCartFrontend.currency)
      let price = 0
      let priceKind = this.selectedVariant.config.priceKind
      const currency = this.shopCartFrontend.currency
      if (!currency) {
        console.error("currency not set")
      }
      if (priceKind == "voluntary") {
        price = this.voluntaryPrice.price
      } else if (priceKind == "package") {
        let count = this.packageUserConfig.selectedCount || 0
        if (count == 0) price = 0
        else {
          // console.log('PPU:', currency, count, this.selectedVariant.price)
          let pricePerUnit =
            this.selectedVariant.price.find(
              (p) => p.currency == currency && p.units == count && p.model == "package"
            ) || 0
          price = pricePerUnit.brutto * count
        }
      } else if (priceKind == "fixed") {
        // console.log(
        //   "priceKind fixed:",
        //   this.selectedVariant,
        //   this.shopCart,
        //   currency
        // )
        const pricing = this.selectedVariant.price.find(
          (p) => p.currency == currency && p.model == "fixed"
        )
        if (!pricing) {
          console.error("no pricing found for currency", currency)
        }
        price = pricing?.brutto
      }
      return parseFloat(price)
    },
  },
}

// database of currencies, their prefixes, suffixes and formatting

export default {
  // fallback
  default: {
    prefix: "",
    prefixSpace: false,
    suffix: "CC", // currency code
    suffixSpace: true,
    numeral: {
      format: "0,0",
      delimiters: {
        thousands: " ",
      },
    },
  },
  CZK: {
    prefix: "",
    prefixSpace: false,
    suffix: "Kč",
    suffixSpace: true,
    numeral: {
      format: "0,0",
      delimiters: {
        thousands: " ",
      },
    },
  },
  USD: {
    prefix: "$",
    prefixSpace: false,
    suffix: "",
    suffixSpace: false,
    numeral: {
      format: "0,0",
      delimiters: {
        thousands: ",",
      },
    },
  },
  EUR: {
    prefix: "€",
    prefixSpace: false,
    suffix: "",
    suffixSpace: false,
    numeral: {
      format: "0,0",
      delimiters: {
        thousands: " ",
      },
    },
  },
}

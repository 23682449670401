import LandingView from "../views/qasima/LandingView.vue"

export default function ({siteVar}) {
  const langDefault = siteVar.lang.default
  return [
    {
      path: "/:lang?",
      name: "LandingView",
      component: LandingView,
      meta: {
        lang: langDefault,
      },
      children: [
        {
          path: "product/:productSKU",
          alias: "produkt/:productSKU",
          name: "ProductDetailView",
          component: () => import("../views/qasima/ProductDetailView.vue"),
          meta: {},
        },
        {
          path: "tos",
          name: "TermsView",
          component: () => import("../views/qasima/TermsView.vue"),
          meta: {},
        },
      ],
    },
    {
      path: "/download/:kind", // invoice | giftCard | product (digital)
      name: "DownloadView",
      component: () => import("../views/DownloadView.vue"),
      meta: {
        // requiresAuth: false,
      },
    },
  ]
}

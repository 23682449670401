// services:
// - facebook pixel
// - google analytics

//     tracked events (platforma uses same naming as gtag):
//     kind     | action (pfm)     | action (gtag)    | action (facebook pixel)
// ----------------------------------------------------------------------------
// [ ] app      | screen_view      | screen_view      | PageView
// [ ] app      | download_file    | -                | -
// [x] commerce | view_item        | view_item        | ViewContent
// [x] commerce | add_payment_info | add_payment_info | AddPaymentInfo
// [x] commerce | add_to_cart      | add_to_cart      | AddToCart
// [x] commerce | purchase         | purchase         | Purchase
// [ ] commerce | remove_from_cart | remove_from_cart | -
// ... there are more, implement later

// if site uses gtag or facebook pixel, it was already installed indexTemplate.js when publishing, so it is globally available

import {useShopCartStore} from "@/stores/shopCart"
import {firestore} from "../firebase/index.js"
import {collection, addDoc, doc} from "firebase/firestore"
import {useAppStore} from "@/stores/app.js"
import {useSiteStore} from "@/stores/site"
import {useShopItemsStore} from "@/stores/shopItems.js"
import {router as r} from "@/router/index.js"
import events from "./events.js"

const url = import.meta.env.VITE_URL_PING

async function init() {
  const lsVisitorId = localStorage.getItem("@platformaone/visitor/id")
  const visitorNew = !lsVisitorId
  const visitorId = lsVisitorId || doc(collection(firestore, "analyticsVisitors")).id
  const sessionId = doc(collection(firestore, "analyticsSessions")).id
  const pageViewId = doc(collection(firestore, "analyticsPageViews")).id

  localStorage.setItem("@platformaone/visitor/id", visitorId)
  sessionStorage.setItem("@platformaone/session/id", sessionId)
  sessionStorage.setItem("@platformaone/pageView/id", pageViewId)

  const payload = {
    action: "init",
    visitorNew,
    ...(await getParams({
      scopes: ["app", "cart", "route", "browser", "window", "location", "document", "viewport"],
    })),
  }

  console.log("INIT:", {payload})
  navigator.sendBeacon(url, JSON.stringify(payload))

  // when switching or closing tab/browser
  document.addEventListener("visibilitychange", async () => {
    const visibilityState = document.visibilityState
    let action
    if (visibilityState === "hidden") {
      action = "pageViewStop"
    } else if (visibilityState === "visible") {
      action = "pageViewStart"
    }

    const payload = {
      action,
      ...(await getParams({scopes: ["viewport"]})),
      pageViewIdPrevious: sessionStorage.getItem("@platformaone/pageView/id"),
    }
    navigator.sendBeacon(url, JSON.stringify(payload))
  })
}

async function pageView({to, from}) {
  const pageViewIdPrevious = sessionStorage.getItem("@platformaone/pageView/id")
  console.log({pageViewIdPrevious, to, from})

  // ignore first page view - it is already tracked in init()
  if (from.name === undefined && from.path === "/") return

  // set new page view id
  const pageViewId = doc(collection(firestore, "analyticsPageViews")).id
  sessionStorage.setItem("@platformaone/pageView/id", pageViewId)

  const payload = {
    action: "pageView",
    ...(await getParams({scopes: ["product", "viewport"]})),
    pageViewId, // overwrite the one from params
    pageViewIdPrevious,
    route: {
      to,
      from,
    },
  }
  console.log('sending pageView', payload)
  navigator.sendBeacon(url, JSON.stringify(payload))
}

// platforma.one event
async function pfe({action, params}) {
  let paramsModified = params
  if (["view_item"].includes(action)) {
    paramsModified = {
      sku: params.item.sku,
      productId: params.item.id,
    }
  }

  const payload = {
    action: "event",
    ...(await getParams({scopes: ["product", "route"]})),
    event: action,
    params: paramsModified,
  }
  navigator.sendBeacon(url, JSON.stringify(payload))
}

async function getParams({scopes}) {
  const params = {}

  // base params
  params.siteId = window.site?.id || null
  params.shopId = window.site?.shopId || null
  params.visitorId = localStorage.getItem("@platformaone/visitor/id")
  params.sessionId = sessionStorage.getItem("@platformaone/session/id")
  params.pageViewId = sessionStorage.getItem("@platformaone/pageView/id")

  // app
  if (scopes.includes("app")) {
    const app = {
      version: window.app?.version || null,
    }
    params.app = app
  }

  // viewport
  if (scopes.includes("viewport")) {
    const appStore = useAppStore()
    const viewport = {
      width: appStore.app.viewport.width,
      height: appStore.app.viewport.height,
      size: appStore.app.viewport.size,
      currentBreakpoint: appStore.app.viewport.currentBreakpoint,
      scrollYPx: appStore.app.scrollY,
      scrollYPct: appStore.app.scrollYPct,
    }
    params.viewport = viewport
  }

  // cart
  if (scopes.includes("cart")) {
    console.log("params cart")
    const lsCart = JSON.parse(localStorage.getItem("@platformaone/store/commerce/cart"))
    const {id: cartId, version: cartVersion} = lsCart || {}
    const cart = {
      id: cartId,
      version: cartVersion,
    }
    params.cart = cart
  }

  // route
  if (scopes.includes("route")) {
    const router = r.r()
    await router.isReady()

    const route = router.currentRoute.value
    const _route = {
      name: route.name,
      path: route.path,
      fullPath: route.fullPath,
      query: route.query,
      params: route.params,
      hash: route.hash,
      meta: route.meta,
    }
    params.route = _route
  }

  // browser
  if (scopes.includes("browser")) {
    const {userAgent, language, languages} = navigator
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const browser = {
      language,
      languages,
      userAgent,
      timezone,
    }
    params.browser = browser
  }

  // window
  if (scopes.includes("window")) {
    const {devicePixelRatio, screen, innerWidth, innerHeight} = window
    const _window = {
      devicePixelRatio,
      screen: {
        width: screen.width,
        height: screen.height,
      },
      innerWidth,
      innerHeight,
    }
    params.window = _window
  }

  // location
  if (scopes.includes("location")) {
    const {href} = window.location
    const location = {
      href,
    }
    params.location = location
  }

  // document
  if (scopes.includes("document")) {
    const {referrer} = document
    const _document = {
      referrer,
    }
    params.document = _document
  }

  // product (selected product from shopItems store)
  if (scopes.includes("product")) {
    const shopItemsStore = useShopItemsStore()
    const product = shopItemsStore.selected
    params.product = product
  }

  return params
}

function event(action, params) {
  console.log("sink event:", action, params)

  const siteStore = useSiteStore()

  // native event
  if (events.includes(action)) {
    pfe({
      action,
      params,
    })
  }

  // google analytics & facebook events
  const lang = siteStore.siteLangCurrent

  // commerce
  let isCommerce = [
    "view_item",
    "add_payment_info",
    "add_to_cart",
    "purchase",
    "remove_from_cart",
  ].includes(action)

  if (isCommerce) {
    if (params && params.item) {
      var item = {
        sku: params.item.sku,
        title: params.item.desc.title[lang],
        quantity: 1,
        price: 123,
      }
    }

    const shopCartStore = useShopCartStore()
    var cart = shopCartStore.cart
  }

  // add to cart
  if (action == "add_to_cart") {
    gte({
      action,
      params: {
        items: [
          {
            item_id: item.sku,
            item_name: item.title,
            quantity: item.quantity,
            price: item.price,
          },
        ],
      },
    })

    fpe({
      action: "AddToCart",
      params: {
        content_ids: [item.sku],
        content_name: item.title,
        content_type: "product",
        contents: [
          {
            id: item.sku,
            quantity: item.quantity,
          },
        ],
      },
    })
  }
  // add payment info
  else if (action == "add_payment_info") {
    console.log("add_payment_info triggered")
    gte({
      action,
    })

    fpe({
      action: "AddPaymentInfo",
    })
  }
  // purchase
  else if (action == "purchase") {
    var cartItemsGTE = cart.items.map((i) => {
      let itemPrice = {}
      let itemPriceKind = i.snapshot.config.priceKind

      if (itemPriceKind == "fixed") {
        itemPrice = i.snapshot.price.find(
          (price) => price.currency == cart.currency && price.model == "fixed"
        )
      } else if (itemPriceKind == "voluntary") {
        itemPrice = i.snapshot.price.find(
          (price) => price.currency == cart.currency && price.model == "voluntary"
        )
      } else if (itemPriceKind == "package") {
        itemPrice = i.snapshot.price.find(
          (price) =>
            price.currency == cart.currency && price.model == "package" && price.units == i.count
        )
      }

      return {
        item_id: i.snapshot.sku,
        item_name: i.snapshot.desc.title[lang],
        currency: cart.currency,
        price: parseInt(itemPrice.netto),
        quantity: parseInt(i.count),
      }
    })

    let FPEcontentIds = cart.items.map((i) => i.snapshot.sku)
    let FPEcontents = cart.items.map((i) => {
      return {id: i.snapshot.sku, quantity: i.count}
    })

    gte({
      action,
      params: {
        currency: cart.currency,
        transaction_id: params.order.orderNumber,
        value: parseInt(cart.total.brutto),
        shipping: parseInt(cart.delivery.price.brutto),
        tax: parseInt(cart.total.brutto - cart.total.netto),
        items: cartItemsGTE,
      },
    })

    fpe({
      action: "Purchase",
      params: {
        content_ids: FPEcontentIds,
        contents: FPEcontents,
        currency: cart.currency,
        value: parseInt(cart.total.brutto),
      },
    })
  }
  // view item
  else if (action == "view_item") {
    gte({
      action,
      params: {
        items: [
          {
            item_id: item.sku,
            item_name: item.title,
            quantity: item.quantity,
            price: item.price,
          },
        ],
      },
    })

    fpe({
      action: "ViewContent",
      params: {
        content_ids: [item.sku],
        content_name: item.title,
        content_type: "product",
        contents: [
          {
            id: item.sku,
            quantity: item.quantity,
          },
        ],
      },
    })
  }
  // file downloaded`
  else if (action == "download_file") {
    gte({
      action,
      params: {
        fileName: params.fileName,
        fileId: params.fileId,
        buttonLabel: params.buttonLabel,
      },
    })
  }
}

// google analytics tag
// default events reference: https://developers.google.com/gtagjs/reference/ga4-events
// recommended events: https://support.google.com/analytics/answer/9267735?hl=en
// https://developers.google.com/analytics/devguides/collection/gtagjs/events
function gte(args) {
  if (typeof gtag !== "function") return

  let action = args.action
  let params = args.params

  /* eslint-disable-next-line */ // gtag is global
  gtag("event", action, params)
}

// facebook pixel
// default events reference: https://developers.facebook.com/docs/facebook-pixel/reference#standard-events
function fpe(args) {
  if (typeof fbq !== "function") return

  let action = args.action
  let params = args.params

  /* eslint-disable-next-line */ // fbq is global
  fbq("track", action, params)
}

export {init, pageView, event}

// formats price according to requested currency

import numeral from "numeral"
import currencies from "../db/currencies.js"

export default function (value, currency) {
  let cf = currencies
  let cc = currency

  if (!cc) return ""

  let f = cf[cc] ? cf[cc] : cf.default // fallback
  let price = ""

  value = parseFloat(value)

  numeral.localeData().delimiters.thousands = f.numeral.delimiters.thousands

  if (f.prefix) price += f.prefix
  if (f.prefixSpace) price += " "
  price += numeral(value).format(f.numeral.format)
  if (f.suffixSpace) price += " "
  if (f.suffix) price += f.suffix == "CC" ? cc : f.suffix // fallback

  return price
}

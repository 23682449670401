<template>
  <div class="w-full text-left" v-if="customerForm">
    <!-- <OneHeading :level="4" align="center" :content="Langs.get('pricePackage-price')" /> -->

    <div v-bind="props.lineWrap" v-for="(field, index) in customerForm" :key="index">
      <div v-bind="props.labelWrap">
        <OneLabel v-bind="props.labelTitle" :text="field.name[currentLang]" />
        <span v-bind="props.labelRequired" v-if="!field.required"
          >({{ Langs.get("cart-form-optional") }})</span
        >
      </div>

      <!-- text short -->
      <OneInput
        v-bind="props.inputShort"
        v-model="field.value"
        label=""
        placeholder="text"
        @update:modelValue="onInput"
        @update:blur="onBlur($event, field)"
        v-if="field.type == 'text-short'"
      />

      <!-- text long -->
      <OneInput
        v-bind="props.inputLong"
        v-model="field.value"
        label=""
        placeholder="text"
        long
        @update:modelValue="onInput"
        @update:blur="onBlur($event, field)"
        v-if="field.type == 'text-long'"
      />

      <!-- single -->
      <OneFormRadio
        v-model="field.value"
        :options="getFieldOptions(field)"
        layout="grid"
        kind="outlined"
        :columns="5"
        @update:modelValue="onInput($event, field)"
        v-if="field.type == 'single'"
      />

      <!-- multiple -->
      <div v-if="field.type == 'multi'">
        <div v-for="(option, oindex) in field.options" :key="oindex">
          <OneToggle
            v-model="field.options[oindex].value"
            align="left"
            :labelTrue="option.name[currentLang]"
            class="btn-toggle"
            @update:modelValue="onInput($event, field, option)"
          />
        </div>
      </div>
      <!-- special links -->
      <!-- size -->
      <!-- TODO: make this intelligently -->
      <div v-if="field.value && field.cs == 'velikost' && sizeGuideRoute">
        <router-link to="/velikosti" target="_blank" class="text-sm text-neutral-600 underline"
          >tabulka velikostí</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import Langs from "@/langs"
import mixinClasses from "@jirizavadil/one-design-system/src/mixins/classes.js"
import {useSiteStore} from "@/stores/site"
import {storeToRefs} from "pinia"
import {event} from "@/sink"

export default {
  name: "CustomerForm",
  mixins: [mixinClasses],
  props: {
    selectedVariant: {
      type: Object,
    },
    layout: {
      type: String, // horizontal | vertical
      default: "vertical",
    },
  },
  emits: ["updated"],
  setup() {
    // stores
    const siteStore = useSiteStore()

    // getters
    const {siteLangCurrent} = storeToRefs(siteStore)

    return {
      siteLangCurrent,
    }
  },
  data: () => ({
    Langs,
    defaults: {
      commonClasses: "",
      root: {},
      lineWrap: {
        propClass: {
          layout: {
            vertical: "mb-6",
            horizontal: "mb-2 flex flex-row justify-between items-center",
          },
        },
      },
      labelWrap: {
        propClass: {
          layout: {
            vertical: "flex flex-row items-end mb-2",
            horizontal: "flex flex-row items-end mb-2",
          },
        },
      },
      labelTitle: {
        propClass: {
          layout: {
            vertical: "",
            horizontal: "!pl-0",
          },
        },
      },
      labelRequired: {
        propClass: {
          layout: {
            vertical: "text-xs text-neutral-500 pl-1 pb-0",
            horizontal: "text-xs text-neutral-500",
          },
        },
      },
      inputShort: {
        propClass: {
          layout: {
            vertical: "w-48 mt-0 mx-auto md:mx-0 text-left w-full",
            horizontal: "w-48 ml-4 text-left",
          },
        },
      },
      inputLong: {
        propClass: {
          layout: {
            vertical: "w-full mt-0 mx-auto text-left",
            horizontal: "w-full ml-4 text-left",
          },
        },
      },
    },
  }),
  computed: {
    currentLang() {
      return this.siteLangCurrent
    },
    customerForm() {
      return this.selectedVariant.config.customerForm
    },
    routes() {
      return this.$router.options.routes
    },
    sizeGuideRoute() {
      return this.routes.find((r) => r.path.endsWith("/velikosti"))
    },
  },
  mounted() {},
  methods: {
    getFieldOptions(field) {
      // console.log("getFieldOptions", field)
      let o = []
      field.options.forEach((f) => {
        o.push({
          name: f.name[this.currentLang],
          value: f.name[this.currentLang],
        })
      })
      return o
    },
    onInput(v, field, option) {
      // console.log("onInput", v, field, option)
      if (["single", "multi"].includes(field.type)) {
        event("ProductCustomerFormFill", {
          itemId: this.selectedVariant.id,
          fieldName: field.name,
          fieldType: field.type,
          ...(field.value && {fieldValue: field.value}),
          ...(option && {optionName: option.name}),
          ...(option && {optionValue: option.value}),
        })
      }
      this.$emit("updated", v)
    },
    onBlur(e, field) {
      if (["text-short", "text-long"].includes(field.type)) {
        event("ProductCustomerFormFill", {
          itemId: this.selectedVariant.id,
          fieldName: field.name,
          fieldValue: field.value,
          fieldType: field.type,
        })
      }
    },
  },
}
</script>

export default [
  "ProductView",
  // itemId: ***
  // itemSku: ***

  "ProductVariantSelect",
  // itemId: ***
  // itemSku: ***
  // itemIdMain: ***

  "ProductCustomerFormFill",
  // itemId: ***
  // fieldName: ***
  // fieldValue: ***

  "ProductDigitalSampleDownload",
  // itemId: ***
  // fileId: ***
  // fileName: ***
  // buttonLabelKey: ***
  // buttonLabel: ***

  "ProductPriceVoluntaryFill",
  // itemId: ***
  // price: ***

  "ProductGiftCardDispatchToSelect",
  // itemId: ***
  // to: customer | recipient

  "ProductGiftCardDesignSelect",
  // itemId: ***
  // option: ***

  "ProductGiftCardMessageFill",
  // itemId: ***

  "ProductGiftCardSenderNameFill",
  // itemId: ***

  "ProductGiftCardSenderEmailFill",
  // itemId: ***

  "ProductGiftCardRecipientNameFill",
  // itemId: ***

  "ProductGiftCardRecipientEmailFill",
  // itemId: ***

  "ProductGiftCardPreviewDownload",
  // itemId: ***
  // fileName: ***
  // buttonLabelKey: ***
  // buttonLabel: ***

  "CartAdd", // (= click buy in product)
  // itemId: ***
  // itemSku: ***

  "CartRemove",
  // itemId: ***

  "CartItemQuantitySubtract",
  // itemId: ***

  "CartItemQuantityIncrement",
  // itemId: ***

  "CartPromoCodeFill",
  // promoCode: ***

  "CartCustomerInfoEmailFill",

  "CartCustomerInfoNameFill",

  "CartCustomerInfoPhoneFill",

  // ...TODO: add other cart events

  "CartPaymentMethodCardFill",

  "CartTermsClick",

  "CartCheckoutInit",
  // cartId: ***
  // method: wallet | card | order (no payment)
  // walletName: *** (apple pay / google pay / ...)
  // value: ***
  // currency: ***
  // labelKey: ***,
  // label: ***

  "CartCheckoutComplete",
  // cartId: ***

  "FooterContactClick",
  // kind: email | phone | instagram | facebook

  "CustomerAreaDownloadInvoice",
  // orderId: ***

  "CustomerAreaDownloadGiftCard",
  // orderId: ***

  "CustomerAreaDownloadDigitalProduct",
  // orderId: ***
  // productId: ***
  // fileId: ***
]

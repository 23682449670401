<script setup>
import {computed} from "vue"
import {useRoute} from "vue-router"
import {useSiteStore} from "@/stores/site.js"
import Menu from "../../components/blocks/main/Menu.vue"
import TextWithHeader from "../../components/blocks/main/TextWithHeader.vue"
import ProductListing from "../../components/blocks/commerce/ProductListing.vue"
import Footer from "../../components/blocks/main/Footer.vue"
import {storeToRefs} from "pinia"
import {event} from "@/sink"

const route = useRoute()
const siteStore = useSiteStore()
const {siteVar, siteLangCurrent} = storeToRefs(siteStore)

const showHeadlineAndText = computed(() => {
  return (
    route.name === "LandingView" &&
    (siteVar.value.content.headline || siteVar.value.content.paragraph)
  )
})
const showProductListing = computed(() => {
  return route.name === "LandingView"
})
const showRouterView = computed(() => {
  return route.name !== "LandingView"
})

const configMenu = computed(() => {
  return {
    config: {
      autohide: false,
      backButtonPosition: "left",
      background: "transparent",
      backgroundOnScroll: "transparent",
      cartButtonIcon: "Cart1",
      cartButtonPosition: "right",
      fixed: false,
      logoPosition: "center",
      name: "menu",
      openedSize: "small",
      shared: false,
      showBackButton: route.name !== "LandingView",
      showLangSwitch: route.name === "LandingView",
      variant: 5,
    },
    _contentsData: {
      content: {
        logo: {
          [siteLangCurrent.value]: siteVar.value.logo,
        },
      },
      stylesConfig: {},
    },
  }
})

const configTextWithHeader = computed(() => {
  return {
    config: {},
    _contentsData: {
      content: {
        header: siteVar.value.content.headline,
        level: 2,
        paragraph: siteVar.value.content.paragraph,
      },
      stylesConfig: {},
    },
  }
})

const configProductListing = computed(() => {
  return {
    config: {
      filterByTags: [],
      layout: "grid",
      limit: false,
      limitCount: 1,
      ordering: [
        {
          by: "price",
          order: "asc",
        },
        {
          by: "title",
          order: "asc",
        },
      ],
      groupVariants: true,
      previewImageRatio: "square",
      productDetailDisplay: "page",
      productDetailPageId: "ProductDetailView", //sitePageProductDetailRef.id,
      showAddToCartButton: true,
      showAllButton: false,
      showItemsAvailable: true,
      showItemsForPreorder: true,
      showItemsUnavailable: true,
    },
  }
})

const configFooter = computed(() => {
  return {
    config: {
      variant: 1,
    },
    _contentsData: {
      content: {
        logo: {
          [siteLangCurrent.value]: siteVar.value.logo,
        },
        email: {
          [siteLangCurrent.value]: siteVar.value.content.email,
        },
        phone: {
          [siteLangCurrent.value]: siteVar.value.content.phone,
        },
        facebook: {
          [siteLangCurrent.value]: siteVar.value.content.facebook,
        },
        instagram: {
          [siteLangCurrent.value]: siteVar.value.content.instagram,
        },
      },
      stylesConfig: {},
    },
  }
})

function footerOnClickLink(kind) {
  console.log("footerOnClickLink", kind)
  event("FooterContactClick", {
    kind,
  })
}
</script>

<template>
  <!-- header -->
  <Menu :block="configMenu" />

  <!-- headline & text -->
  <TextWithHeader :block="configTextWithHeader" v-if="showHeadlineAndText" />

  <!-- product listing -->
  <ProductListing :block="configProductListing" v-if="showProductListing" />

  <!-- other pages -->
  <RouterView v-if="showRouterView" />

  <!-- footer -->
  <Footer :block="configFooter" @clickLink="footerOnClickLink" />
</template>

<template>
  <p v-html="content" v-bind="props.root"></p>
</template>

<script>
import mixinClasses from "../../../mixins/classes"

export default {
  name: "oneParagraph",
  mixins: [mixinClasses],
  props: {
    content: {
      type: String,
      default:
        "",
    },
    align: {
      type: String,
      _setsClass: true,
      default: "left",
      validator: (val) => ["default", "left", "center", "right", "justify"].includes(val),
    },
    color: {
      type: String,
      _setsClass: true,
      default: "default",
      validator: (val) =>
        [
          "default",
          "primary-dark",
          "primary",
          "primary-light",
          "secondary-dark",
          "secondary",
          "secondary-light",
          "tertiary-dark",
          "tertiary",
          "tertiary-light",
          "white",
          "black",
        ].includes(val),
    },
    size: {
      type: String,
      default: "base",
    },
  },
  data: () => ({
    defaults: {
      commonClasses: "",
      root: {
        class: `font-body !leading-[1.5rem] whitespace-pre-wrap w-full`,
        propClass: {
          align: {
            default: "text-left",
            left: "text-left",
            center: "text-center",
            right: "text-right",
            justify: "text-justify",
          },
          color: {
            default: "text-neutral-800",
            primary: "text-primary",
            "primary-light": "text-primary-light",
            "primary-dark": "text-primary-dark",
            secondary: "text-secondary",
            "secondary-light": "text-secondary-light",
            "secondary-dark": "text-secondary-dark",
            tertiary: "text-tertiary",
            "tertiary-light": "text-tertiary-light",
            "tertiary-dark": "text-tertiary-dark",
            white: "text-white",
            black: "text-black",
          },
          size: {
            xs: "text-xs pt-2",
            sm: "text-sm pt-2",
            base: "text-base pt-1",
            lg: "text-lg pt-1",
            xl: "text-xl pt-1",
            "2xl": "text-2xl",
            "3xl": "text-3xl",
            "4xl": "text-4xl",
            "5xl": "text-5xl",
            "6xl": "text-6xl",
          },
        },
      },
    },
  }),
}
</script>

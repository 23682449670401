<template>
  <div
    class="w-full"
    :class="{'flex flex-row items-center justify-between': layout == 'horizontal'}"
  >
    <OneHeading :level="6" :content="Langs.get('priceVoluntary-price')" />

    <!-- voluntary price -->
    <PfmInput
      class="w-full"
      :class="{'mt-4': layout == 'vertical'}"
      name="voluntaryPrice"
      v-model="voluntaryPrice.price"
      :label="price.currency"
      regex="[0-9]"
      kind="input"
      inputEvent="onkeyup"
      :required="true"
      :placeholder="pricePlaceholder"
      @update:modelValue="onInput"
      @update:blur="onBlur"
    />
  </div>
</template>

<script>
import Langs from "@/langs"

import {useShopCartStore} from "@/stores/shopCart"
import {storeToRefs} from "pinia"
import {event} from "@/sink"

export default {
  name: "PriceVoluntary",

  props: {
    selectedVariant: {
      type: Object,
    },
    voluntaryPrice: {
      type: Object,
    },
    layout: {
      type: String,
      default: "vertical", // horizontal | vertical
    },
  },
  emits: ["priceValidityChange", "updated"],
  setup() {
    // stores
    const shopCartStore = useShopCartStore()

    // states
    const {frontend: shopCartFrontend} = storeToRefs(shopCartStore)

    return {
      shopCartFrontend,
    }
  },
  data: () => ({
    Langs,
  }),
  watch: {
    priceValid: {
      handler(newValue, oldValue) {
        if (newValue != oldValue) this.$emit("priceValidityChange", newValue)
      },
    },
  },
  computed: {
    priceValid() {
      return (
        parseFloat(this.voluntaryPrice.price) >= parseFloat(this.price.min) &&
        parseFloat(this.voluntaryPrice.price) <= parseFloat(this.price.max)
      )
    },
    price() {
      return this.selectedVariant.price.find(
        (p) => p.model == "voluntary" && p.currency == this.shopCartFrontend.currency
      )
    },
    pricePlaceholder() {
      return `${this.price.min} - ${this.price.max}`
    },
  },
  created() {},
  methods: {
    onInput() {
      this.$emit("updated")
    },
    onBlur() {
      event("ProductPriceVoluntaryFill", {
        itemId: this.selectedVariant.id,
        price: this.voluntaryPrice.price,
      })
    },
  },
}
</script>

import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore} from "@/firebase/index.js"
import {collection, query, where, documentId} from "firebase/firestore"

export const useShopItemLabelGroupsStore = defineStore("shopItemLabelGroups", () => {
  // state
  const groups = ref([])
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const shopItemLabelGroupsLoaded = computed(() => status.value == "loaded")

  // actions
  async function bind(args) {
    const filter = args.filter
    const shopId = window.site.shopId
    const collectionRef = collection(firestore, `shops/${shopId}/itemLabelGroups`)
    const q = query(collectionRef, where(documentId(), "in", filter))
    return await this.attach("groups", q)
  }

  async function unbind() {
    return await this.detach()
  }

  return {
    // state
    groups,
    status,
    unsubscribe,

    // getters
    shopItemLabelGroupsLoaded,

    // actions
    bind,
    unbind,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useShopItemLabelGroupsStore, import.meta.hot))
}

<template>
  <div
    class="one-section-menu relative"
    :data-one-component="$options.name"
    v-bind="props.root"
    :style="menuStyle"
  >
    <div
      class="pt-6 pb-2 md:pb-4 grid grid-cols-[minmax(0,_1fr)_3fr_minmax(0,_1fr)] m-auto max-w-7xl px-4 2xl:px-0 w-full"
    >
      <!-- lang switch -->
      <div v-if="showLangSwitch && siteLangs.length > 1" v-bind="props.langWrap">
        <!-- <OneLink
          v-bind="{...props.menuLang, path: `/${lang.code}`, switchLangTo: lang.code}"
          v-for="(lang, index) in siteLangsFull"
          :key="`lang-${index}`"
          >{{ lang.title }}</OneLink
        > -->
        <RouterLink
          class="text-neutral-500"
          :to="{name: $route.name, params: {lang: lang.code}}"
          v-for="(lang, index) in siteLangsFull"
          :key="`lang-${index}`"
        >
          {{ lang.title }}
        </RouterLink>
      </div>

      <!-- back button -->
      <OneButton
        type="icon"
        size="auto"
        class="col-span-1 justify-self-start"
        v-bind="props.backButton"
        icon="ArrowLeft"
        v-if="showBackButton"
        @update:click="onBackBtnClick()"
      />

      <!-- flex filler if back button disabled -->
      <!-- <span class="btn-back-fill" v-if="showBackBtnFill"></span> -->

      <!-- logo -->
      <!--<OneImage
        class="object-contain col-start-2 col-span-1 justify-self-center max-h-[6rem] children:max-h-[6rem] max-w-[13rem] !w-auto children:w-auto cursor-pointer"
        v-bind="props.logo"
        v-if="props.logo && props.logo.src"
        @click="onLogoClick()"
      />-->
      <img
        class="col-start-2 col-span-1 justify-self-center max-w-[13rem] max-h-[4rem]"
        :src="props.logo.src"
        @click="onLogoClick()"
        v-if="props?.logo?.src"
      />

      <!-- commerce: cart button -->
      <OneButton
        class="col-span-1 col-start-3 justify-self-end"
        type="icon"
        size="auto"
        v-bind="props.cartButton"
        icon="Cart1"
        v-if="showCartButton && !showHamburger"
        @update:click="onCartBtnClick()"
      />

      <!-- flex filler if cart button disabled -->
      <!-- <span class="btn-cart-fill" v-if="showCartBtnFill"></span> -->
    </div>
  </div>
</template>

<script>
import mixinClasses from "../../../mixins/classes"

export default {
  name: "OneMenu5",
  mixins: [mixinClasses],
  props: {
    openedSize: {
      type: String,
      default: "small",
    },
    fixed: {
      type: Boolean,
      default: false,
    },
    autohide: {
      type: Boolean,
      default: true,
    },
    showHamburgerButton: {
      type: Boolean,
      default: true,
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
    showCartButton: {
      type: Boolean,
      default: false,
    },
    showLangSwitch: {
      type: Boolean,
      default: false,
    },

    // positions
    hamburgerWrapPosition: {
      type: String,
      default: "right",
    },
    cartButtonIcon: {
      type: String,
      default: "Cart1",
    },
    cartButtonPosition: {
      type: String,
      default: "right",
    },
    logoPosition: {
      type: String,
      default: "center",
    },
    backButtonPosition: {
      type: String,
      default: "left",
    },

    keepLogoCentered: {
      type: Boolean,
      default: true,
    },
    menuItems: {
      type: Array,
    },
    background: {
      type: String,
      default: "transparent",
    },
    currentLang: {
      type: String,
    },
    siteLangs: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:logoClick", "update:backBtnClick", "update:cartBtnClick"],
  data: () => ({
    prevScrollPos: 0,
    menuStyle: null,
    menuOpen: false,
    menuVisible: true,
    defaults: {
      commonClasses: "",
      root: {
        class: "w-full transition-all duration-500 ease-in-out",
        propClass: {
          fixed: {
            true: "fixed top-0 z-50",
            false: "relative z-0",
          },
          background: {
            white: "bg-white",
            transparent: "",
          },
        },
      },
      container: {
        class: "flex flex-row justify-between items-center w-full",
        contentLayout: "grid",
        contentAlign: "none",
      },
      langWrap: {
        class: "col-span-1 row-start-1 row-span-1 self-center",
        propClass: {},
      },
      hamburgerWrap: {
        class: "flex flex-row justify-center items-center col-span-1 row-start-1 row-span-1 z-20",
        propClass: {
          hamburgerWrapPosition: {
            left: "col-start-1 justify-self-start",
            center: "col-start-2 justify-self-center",
            right: "col-start-3 justify-self-end",
          },
        },
      },
      backButton: {
        // class: "col-span-1 row-start-1 row-span-1",
        propClass: {
          // backButtonPosition: {
          //   left: "col-start-1 justify-self-start",
          //   center: "col-start-2 justify-self-center",
          //   right: "col-start-3 justify-self-end",
          // },
        },
      },
      logo: {
        // class: "cursor-pointer col-span-1 row-start-1 row-span-1 flex-center",
        // propClass: {
        //   logoPosition: {
        //     left: "col-start-1 justify-self-start",
        //     center: "col-start-2 justify-self-center",
        //     right: "col-start-3 justify-self-end",
        //   },
        // },
        // fit: "contain",
        // style: {
        //   width: "100%",
        //   maxWidth: "200px",
        //   maxHeight: "30px",
        //   alignSelf: "center",
        // },
      },
      cartButton: {
        // class: "col-span-1 row-start-1 row-span-1",
        // propClass: {
        //   cartButtonPosition: {
        //     left: "col-start-1 justify-self-start",
        //     center: "col-start-2 justify-self-center",
        //     right: "col-start-3 justify-self-end",
        //   },
        // },
      },
      menuLang: {
        class: "px-3 my-1 py-1 text-dark",
      },
    },
  }),
  computed: {
    siteLangsFull() {
      let langLength = "short"
      let l = []
      let langsMap = {
        cs: {
          short: "cs",
          long: "česky",
        },
        en: {
          short: "en",
          long: "english",
        },
      }

      this.siteLangs.forEach((lang) => {
        if (lang != this.currentLang) {
          l.push({
            title: langsMap[lang][langLength],
            code: lang,
          })
        }
      })

      return l
    },
    showHamburger() {
      return this.showHamburgerButton && this.menuItems.length > 0
    },
    showBackBtnFill() {
      // return true
      return this.keepLogoCentered && !this.showBackButton && !this.showHamburger
    },
    showCartBtnFill() {
      return this.keepLogoCentered && !this.showCartButton && this.showHamburger
    },
  },
  created() {
    if (this.autohide) this.enableAutohide()
  },
  methods: {
    enableAutohide() {
      window.addEventListener("scroll", (e) => {
        this.scrolled(e)
      })
    },
    scrolled() {
      let currentScrollPos = window.pageYOffset
      // console.log('scrolled:', currentScrollPos, this.prevScrollPos, e)
      if (this.prevScrollPos >= currentScrollPos) {
        if (!this.menuVisible) this.showMenu()
      } else {
        if (this.menuVisible && this.prevScrollPos >= 0) this.hideMenu()
      }
      this.prevScrollPos = currentScrollPos
    },
    showMenu() {
      // console.log('showMenu()')
      this.menuStyle = {
        top: "0",
      }
      this.menuVisible = true
    },
    hideMenu() {
      // console.log('hideMenu()')
      this.menuStyle = {
        top: "-100px",
      }
      this.menuVisible = false
      this.menuOpen = false
    },
    onLogoClick() {
      this.$emit("update:logoClick")
    },
    onBackBtnClick() {
      this.$emit("update:backBtnClick")
    },
    onCartBtnClick() {
      this.$emit("update:cartBtnClick")
    },
  },
}
</script>

<style scope lang="less">
.btn-back-fill,
.btn-cart-fill {
  width: 48px;
  height: 48px;
}
</style>

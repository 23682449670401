<template>
  <OneContainer class="section-text-with-header" contentAlign="left">
    <div class="w-full">
      <OneHeading :level="level" :align="textAlign" :content="header" v-if="header" />
      <OneParagraph :align="textAlign" :content="paragraph" v-if="paragraph" />
    </div>
  </OneContainer>
</template>

<script>
import mixinBlock from "@/mixins/block"
import {useSiteStore} from "@/stores/site"
import {storeToRefs} from "pinia"

export default {
  name: "pfmTextWithHeader",
  mixins: [mixinBlock],
  props: ["block"],
  setup() {
    // stores
    const siteStore = useSiteStore()

    // getters
    const {siteLangCurrent} = storeToRefs(siteStore)

    return {
      siteLangCurrent,
    }
  },
  computed: {
    textAlign() {
      if (!this.stylesConfig) return
      return this.stylesConfig.textAlign
    },
    header() {
      if (!this.c) return
      let v = this.c.header
      if (!v || !v[this.siteLangCurrent]) return
      return v[this.siteLangCurrent]
    },
    level() {
      if (!this.c) return
      return this.c.level || 2
    },
    element() {
      return `h${this.level}`
    },
    paragraph() {
      if (!this.c) return
      let v = this.c.paragraph
      if (!v || !v[this.siteLangCurrent]) return
      return v[this.siteLangCurrent]
    },
  },
}
</script>

<style scoped lang="less">
p {
  white-space: pre-wrap;
}
</style>

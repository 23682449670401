import {createRouter, createWebHistory} from "vue-router"
import Page from "@/components/Page.vue"
import Add from "@/components/Add.vue"
import Langs from "@/langs"
import Debug from "@/components/Debug.vue"
import {useAppStore} from "@/stores/app.js"
import {useSiteStore} from "@/stores/site.js"
import {useShopItemsStore} from "@/stores/shopItems.js"
import {routes as appRoutesPlatformaone} from "./routesPlatformaone.js"
import appRoutesQasima from "./routesQasima.js"
import {pageView} from "@/sink/index.js"

var routerInstance = null

const r = {
  init: () => {
    // stores
    const appStore = useAppStore()
    const siteStore = useSiteStore()
    const shopItemsStore = useShopItemsStore()
    const sitePages = siteStore.siteVar.pages

    let pagesWithProductDetailBlock = []
    let routes = []
    let availableLangs = siteStore.siteLangsAvailable
    const platform = siteStore.siteVar.global.platform

    if (["qasima.ae", "qasima.cz"].includes(platform)) {
      routes = appRoutesQasima({siteVar: siteStore.siteVar})
      shopItemsStore.itemDetailPageId = "ProductDetailView"
    }

    if (["platforma.one"].includes(platform)) {
      routes = appRoutesPlatformaone
    }

    // find pages containing product detail block, so we can catch the product sku param in url
    sitePages.forEach((page) => {
      if (page.containsProductDetailBlock) {
        shopItemsStore.itemDetailPageId = page.id
        pagesWithProductDetailBlock.push(page.id)
      }
    })

    console.log({availableLangs})
    // construct site routes
    availableLangs.forEach((lang) => {
      sitePages.forEach((p) => {
        const pageContainsProductDetailBlock = pagesWithProductDetailBlock.includes(p.id)
        const pageRoute = getPageRoute(p.id, sitePages, lang)
        let fixedProduct = null
        let route = {}

        // if page has product detail block, check if the product is fixed
        if (pageContainsProductDetailBlock) {
          p.blocks.forEach((b) => {
            if (b.config.fixedProduct && b.config.fixedProductSKU)
              fixedProduct = b.config.fixedProduct
          })
        }

        // homepage
        if (pageRoute == "/") {
          route = {
            path: `/${lang}`,
            name: `${p.id}-${lang}`,
            component: Page,
            meta: {
              id: p.id,
              slug: "/",
              lang,
            },
          }
        } else {
          route = {
            path:
              pageContainsProductDetailBlock && !fixedProduct
                ? `/${lang}${pageRoute}/:productSKU`
                : `/${lang}${pageRoute}`,
            name: `${p.id}-${lang}`,
            component: Page,
            meta: {
              id: p.id,
              slug: `/${lang}${pageRoute}`,
              lang,
            },
          }
        }
        routes.push(route)

        // pages in default lang also have route without lang specified
        if (lang == siteStore.siteLangDefault) {
          route = {
            path:
              pageContainsProductDetailBlock && !fixedProduct
                ? `${pageRoute}/:productSKU`
                : `${pageRoute}`,
            name: `${p.id}-default`,
            component: Page,
            meta: {
              id: p.id,
              slug: `${pageRoute}`,
              lang,
            },
          }
          routes.push(route)
        }
      })
    })

    // system routes
    const itemAddRoute = {
      path: `/add/:productSKU`,
      name: `add`,
      component: Add,
    }
    routes.push(itemAddRoute)

    // system routes
    const accountRoute = {
      path: `/account`,
      name: `accountView`,
      component: () => import("../views/accountView.vue"),
      children: [
        {
          path: `videos`,
          name: `accountVideosView`,
          component: () => import("../views/accountVideosView.vue"),
          children: [
            {
              path: `:videoId`,
              name: "accountVideosVideoView",
              component: () => import("../views/accountVideosVideoView.vue"),
            },
          ],
        },
      ],
    }
    routes.push(accountRoute)

    // debug route
    const debugRoute = {
      path: "/debug",
      name: "debug",
      component: Debug,
      meta: {},
    }
    routes.push(debugRoute)

    // create router
    routerInstance = createRouter({
      history: createWebHistory(),
      routes,
      scrollBehavior(to, from, savedPosition) {
        console.log("savedPosition:", savedPosition)
        return new Promise((resolve) => {
          setTimeout(() => {
            let res = {}
            if (savedPosition) {
              res = savedPosition
            } else {
              res = {top: 0, left: 0}
            }
            resolve(res)
          }, 250)
        })
      },
    })

    // set page lang
    routerInstance.beforeEach((to, from, next) => {
      if (to.params.productSKU && !from.params.productSKU) {
        // this is to point back button to the right parent page
        appStore.app.lastProductDetailOpenedFrom = from
      }
      if (to.meta.lang && to.meta.lang != siteStore.siteLangCurrent) {
        siteStore.setCurrentLang({lang: to.meta.lang})
        Langs.setLang(to.meta.lang)
        console.log("switching to lang:", to.meta.lang)
      }
      if (["qasima.ae", "qasima.cz"].includes(platform)) {
        if (to.params.lang) {
          siteStore.setCurrentLang({lang: to.params.lang})
          Langs.setLang(to.params.lang)
          console.log("switching to lang:", to.params.lang)
        }
      }
      next()
    })

    // log page view
    routerInstance.afterEach((to, from, failure) => {
      if (!failure)
        pageView({
          to: {
            name: to.name,
            path: to.path,
            params: to.params,
            query: to.query,
            meta: to.meta,
          },
          from: {
            name: from.name,
            path: from.path,
            params: from.params,
            query: from.query,
            meta: from.meta,
          },
        })
    })
  },

  r: () => {
    return routerInstance
  },
}

function getPageRoute(pageId, all, lang) {
  let route = "/"
  const page = all.find((p) => p.id == pageId)
  if (page !== undefined && page.slug !== undefined) {
    route += page.slug[lang]
    // Try to get parent path
    if (page.treeParentId != "root") {
      let parentPath = getPageRoute(page.treeParentId, all, lang)
      if (parentPath != "/") {
        route = parentPath + route
      }
    }
  }
  return route
}

export const router = r

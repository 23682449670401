import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore} from "@/firebase/index.js"
import {query, doc, orderBy, startAt, endAt, documentId, collectionGroup} from "firebase/firestore"

export const useShopItemLabelValuesStore = defineStore("shopItemLabelValues", () => {
  // state
  const values = ref([])
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const shopItemLabelValuesLoaded = computed(() => status.value == "loaded")

  // actions
  async function bind() {
    const shopId = window.site.shopId
    const shopRef = doc(firestore, `shops/${shopId}`)
    const collectionRef = collectionGroup(firestore, `itemLabelValues`)
    const q = query(
      collectionRef,
      orderBy(documentId()),
      startAt(shopRef.path),
      endAt(shopRef.path + `\uf8ff`)
    )
    return await this.attach("values", q)
  }

  async function unbind() {
    return await this.detach()
  }

  return {
    // state
    values,
    status,
    unsubscribe,

    // getters
    shopItemLabelValuesLoaded,

    // actions
    bind,
    unbind,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useShopItemLabelValuesStore, import.meta.hot))
}

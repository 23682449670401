<template>
  <div class="w-full text-center md:text-left flex flex-row justify-between items-center">
    <OneLabel text="varianta" />
    <OneFormRadio v-model="selectedVariantId" :options="variantOptions" layout="list" />
  </div>
</template>
<script type="text/javascript">
import Langs from "@/langs"
import sortBy from "lodash/sortBy"
import {useShopItemsStore} from "@/stores/shopItems"
import {useSiteStore} from "@/stores/site"
import {storeToRefs} from "pinia"

export default {
  name: "Variants",
  props: {
    selectedVariant: Object,
  },
  emits: ["select"],
  setup() {
    // stores
    const shopItemsStore = useShopItemsStore()
    const siteStore = useSiteStore()

    // states

    // getters
    const {siteLangCurrent} = storeToRefs(siteStore)

    // actions
    const {getItemById: shopItemsGetItemById, getItemVariants: shopItemsGetItemVariants} =
      shopItemsStore

    return {
      siteLangCurrent,
      shopItemsGetItemById,
      shopItemsGetItemVariants,
    }
  },
  data() {
    return {
      Langs,
    }
  },
  computed: {
    currentLang() {
      return this.siteLangCurrent
    },
    selectedVariantId: {
      get() {
        return this.selectedVariant.id
      },
      set(v) {
        let selectedVariant = this.allVariants.find((variant) => variant.id == v)
        this.selectVariant(selectedVariant)
      },
    },
    mainItem() {
      if (this.selectedVariant.parentId == "main") return this.selectedVariant
      else return this.shopItemsGetItemById(this.selectedVariant.parentId)
    },
    // sub variants of the main product
    variants() {
      return this.shopItemsGetItemVariants(this.mainItem.id)
    },
    // all variants, including the main product
    allVariants() {
      let variants = [...this.variants, this.mainItem]
      variants = sortBy(variants, ["sku"])
      return variants
    },
    variantOptions() {
      let o = []
      this.allVariants.forEach((v) => {
        if (v._display) {
          o.push({
            name: this.variantLabel(v),
            value: v.id,
            disabled: v._display == "unavailable",
          })
        }
      })
      return o
    },
  },
  methods: {
    variantLabel(variant) {
      let productKind = this.selectedVariant.config.productKind
      if (productKind == "physical") {
        if (!variant || !variant.attribs) return
        // hack to support at least a single option, until we figure it out
        let attribKey = Object.keys(variant.attribs)[0]
        if (!attribKey) return
        return variant.attribs[attribKey][this.currentLang]
      } else if (productKind == "virtual") {
        if (variant.config.priceKind == "voluntary") return Langs.get("priceVoluntary-voluntary")
        else return variant.price[0].brutto + " Kč"
      } else if (productKind == "no-delivery") {
        if (!variant || !variant.attribs) return
        // hack to support at least a single option, until we figure it out
        let attribKey = Object.keys(variant.attribs)[0]
        if (!attribKey) return
        return variant.attribs[attribKey][this.currentLang]
      }
    },
    selectVariant(variant) {
      this.$emit("select", variant)
    },
  },
}
</script>

import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore} from "@/firebase/index.js"
import {collection} from "firebase/firestore"

export const useShopItemLabelsStore = defineStore("shopItemLabels", () => {
  // state
  const labels = ref([])
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const shopItemLabelsLoaded = computed(() => status.value == "loaded")

  // actions
  async function bind() {
    const shopId = window.site.shopId
    const collectionRef = collection(firestore, `shops/${shopId}/itemLabels`)
    return await this.attach("labels", collectionRef)
  }

  async function unbind() {
    return await this.detach()
  }

  return {
    // state
    labels,
    status,
    unsubscribe,

    // getters
    shopItemLabelsLoaded,

    // actions
    bind,
    unbind,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useShopItemLabelsStore, import.meta.hot))
}

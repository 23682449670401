<template>
  <div>
    <!-- filter -->
    <ProductListingFilter
      :block="block"
      :products="products"
      @update:modelValue="onProductListingFilterChange"
      v-if="block.config.showFilters"
    />

    <!-- products -->
    <OneContainer class="">
      <!-- loading -->
      <div v-if="!loaded">
        <Pfm-loading center />
      </div>

      <!-- items -->
      <div :class="ec('productListingWrap', 'layout')" v-if="loaded">
        <div
          :class="ec('productListing', 'layout')"
          data-section-name="product listing"
          v-if="products.length > 0"
        >
          <slot>
            <!-- products -->
            <ProductListingItem
              v-for="product in productsLimited"
              :product="product"
              :block="block"
              :previewImageRatio="block.config.previewImageRatio"
              :key="product.id"
              :layout="layout"
            />
          </slot>
        </div>

        <!-- no products -->
        <div class="no-products" v-if="products.length == 0">
          {{ Langs.get("commerce-no-products-available") }}
        </div>

        <!-- show all products button -->
        <div class="py-10 text-center" v-if="block && block.config && block.config.showAllButton">
          <OneButton class="m-auto" @update:click="forceShowAll = true" v-if="displayShowAllBtn">{{
            Langs.get("commerce-display-all")
          }}</OneButton>
        </div>
      </div>

      <!-- add to cart summary button -->
      <div class="fixed bottom-0 z-50 flex w-full flex-center" v-if="showAddToCartButton">
        <div
          class="mx-4 my-4 flex w-full cursor-pointer flex-row justify-between rounded-lg pl-4 pr-4 text-white transition-all duration-500 ease-in-out md:w-1/2"
          :class="[
            {'bg-neutral-200': !addToCartActive},
            {'bg-primary hover:bg-primary-light': addToCartActive},
          ]"
          @click="addListToCart()"
        >
          <OneLoading class="py-2" v-if="addingListToCart" />
          <div class="py-4 uppercase" v-if="!addingListToCart">
            <span
              class="mr-2 inline-block h-6 w-6 rounded-full text-center"
              :class="[{'bg-neutral-200': !addToCartActive}, {'bg-primary-light': addToCartActive}]"
              >{{ shoppingListItemsCount }}</span
            >
            {{ Langs.get("commerce-go-to-cart") }}
          </div>
          <div class="py-4" v-if="!addingListToCart">
            {{ itemListPriceTotal }}
          </div>
        </div>
      </div>
    </OneContainer>
  </div>
</template>

<script>
import mixinBlock from "@/mixins/block"

import ProductListingItem from "@/components/elements/commerce/ProductListingItem.vue"
import ProductListingFilter from "./ProductListingFilter.vue"
import {calculate} from "@platformaone/common/commerce"
import Utils from "@/utils"
import Langs from "@/langs"
import {useAppStore} from "@/stores/app"
import {useShopStore} from "@/stores/shop"
import {useShopCartStore} from "@/stores/shopCart"
import {useShopItemsStore} from "@/stores/shopItems"
import {useShopShoppingListStore} from "@/stores/shopShoppingList"
import {useSiteStore} from "@/stores/site"
import {storeToRefs} from "pinia"

export default {
  name: "ProductListing",
  mixins: [mixinBlock],
  props: ["block"],
  components: {
    ProductListingItem,
    ProductListingFilter,
  },
  setup() {
    // stores
    const appStore = useAppStore()
    const shopStore = useShopStore()
    const shopCartStore = useShopCartStore()
    const shopItemsStore = useShopItemsStore()
    const shopShoppingListStore = useShopShoppingListStore()
    const siteStore = useSiteStore()

    // states
    const {app} = storeToRefs(appStore)
    const {frontend: shopCartFrontend} = storeToRefs(shopCartStore)
    const {items: shopItems} = storeToRefs(shopItemsStore)
    const {items: shopShoppingListItems} = storeToRefs(shopShoppingListStore)

    // getters
    const {shopLoaded} = storeToRefs(shopStore)
    const {shopItemsLoaded} = storeToRefs(shopItemsStore)
    const {siteLangCurrent} = storeToRefs(siteStore)

    // actions
    const {itemAdd: shopCartItemAdd} = shopCartStore
    const {clear: shopShoppingListClear} = shopShoppingListStore

    return {
      app,
      siteLangCurrent,
      shopLoaded,
      shopItems,
      shopItemsLoaded,
      shopCartItemAdd,
      shopCartFrontend,
      shopShoppingListClear,
      shopShoppingListItems,
    }
  },
  data: () => ({
    Langs,
    addingListToCart: false,
    productsReady: false,
    forceShowAll: false,
    productListingFilter: null,
    elementClasses: {
      productListingWrap: {
        layout: {
          list: "w-full flex flex-col items-center",
          grid: "w-full",
        },
      },
      productListing: {
        layout: {
          list: "w-full",
          grid: "grid grid-cols-1 gap-4 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 lg:gap-8",
        },
      },
    },
  }),
  watch: {
    // products(newValue, oldValue) {
    // },
    productsLimited: {
      // deep: true,
      handler() {
        // console.log('productsLimited watch')
        this.productsReady = true
      },
      deep: true,
    },
  },
  computed: {
    loaded() {
      return this.productsReady && this.shopLoaded
    },
    layout() {
      return this.block.config.layout ? this.block.config.layout : "grid"
    },
    products() {
      let products = this.shopItems ? this.shopItems : false
      if (products) {
        let productsVisible = products.filter((p) => p.display !== false)
        let productsFiltered = this.filter(productsVisible)
        let productsOrdered = this.order(productsFiltered)
        return productsOrdered
      } else {
        return []
      }
    },
    productsVariantsGrouped() {
      if (this.block.config?.groupVariants) {
        return this.products.filter((p) => p.parentId == "main")
      } else {
        return this.products
      }
    },
    productsLimited() {
      if (!this.block.config?.limit || this.forceShowAll) {
        return this.productsVariantsGrouped
      } else {
        return this.productsVariantsGrouped.slice(0, this.block.config.limitCount)
      }
    },
    displayShowAllBtn() {
      return !this.forceShowAll && this.products.length > this.block.config.limitCount
    },
    allowedItems() {
      let ai = []
      if (this.block.config?.showItemsAvailable) ai.push("available")
      if (this.block.config?.showItemsUnavailable) ai.push("unavailable")
      if (this.block.config?.showItemsForPreorder) ai.push("preorder")
      return ai
    },
    shoppingList() {
      return this.shopShoppingListItems
    },
    shoppingListItemsCount() {
      return this.shopShoppingListItems.length
    },
    addToCartActive() {
      return this.shoppingListItemsCount > 0
    },
    itemListPriceTotal() {
      if (!this.shoppingList) return
      let total = calculate({
        items: this.shopShoppingListItems,
        currency: this.shopCartFrontend.currency,
      })
      console.log("itemListPriceTotal()", total, this.shoppingList)
      if (!total) return
      let totalProducts = total.filter((i) => i.type == "products")
      if (!totalProducts) return
      let totalProductsAllVats = 0
      totalProducts.forEach((i) => (totalProductsAllVats += i.brutto))
      return Utils.formatPrice(totalProductsAllVats)
    },
    showAddToCartButton() {
      return (
        this.layout == "list" &&
        this.block.config.showAddToCartButton &&
        ((this.block.config.showAddToCartButtonOnlyIfItemsOnList === true &&
          this.shoppingListItemsCount >= 1) ||
          !this.block.config.showAddToCartButtonOnlyIfItemsOnList)
      )
    },
  },
  created() {
    if (this.productsLimited.length > 0 || (this.shopItemsLoaded && this.products.length == 0)) {
      this.productsReady = true
    }
    // console.log('ProductListing: created', JSON.parse(JSON.stringify(this.productsLimited)))
    if (this.app.mode == "design") {
      return
    }
  },
  methods: {
    onProductListingFilterChange(filter) {
      this.productListingFilter = filter
    },
    ec(element, prop) {
      return this.elementClasses[element][prop][this.layout]
    },
    order(products) {
      let orderedProducts = products
      let ordering = []
      if (this.block && this.block.config && this.block.config.ordering) {
        ordering = this.block.config.ordering
      } else {
        ordering = [
          {
            by: "title",
            order: "asc",
          },
        ]
      }

      // console.log('order():', ordering)

      orderedProducts.sort((a, b) => {
        let sorted = 0
        let i = 0

        while (sorted === 0 && i < ordering.length) {
          let key = ordering[i]

          // availability [_display] (available, unavailable, preorder, ...)
          if (key.by == "availability") {
            if (a._display == b._display) sorted = 0
            if (key.order == "asc") {
              if (a._display < b._display) sorted = -1
              if (a._display > b._display) sorted = 1
            } else if (key.order == "desc") {
              if (a._display > b._display) sorted = -1
              if (a._display < b._display) sorted = 1
            }
          }

          // date updated
          else if (key.by == "dateUpdated") {
            let dateA = a.meta.updated ? a.meta.updated.toDate() : 0
            let dateB = b.meta.updated ? b.meta.updated.toDate() : 0
            if (dateA == dateB) sorted = 0
            if (key.order == "asc") {
              if (dateA < dateB) sorted = -1
              if (dateA > dateB) sorted = 1
            }
            if (key.order == "desc") {
              if (dateA > dateB) sorted = -1
              if (dateA < dateB) sorted = 1
            }
          }

          // date created
          else if (key.by == "dateCreated") {
            let dateA = a.meta.created ? a.meta.created.toDate() : 0
            let dateB = b.meta.created ? b.meta.created.toDate() : 0
            if (dateA == dateB) sorted = 0
            if (key.order == "asc") {
              if (dateA < dateB) sorted = -1
              if (dateA > dateB) sorted = 1
            }
            if (key.order == "desc") {
              if (dateA > dateB) sorted = -1
              if (dateA < dateB) sorted = 1
            }
          }

          // price
          else if (key.by == "price") {
            // TODO: this will not work for package priced products. not kinda simple to do.
            // let currency = 'XX'
            // let priceKindA = a.config.priceKind
            // let priceKindB = b.config.priceKind
            // if (priceKindA == 'package') {
            //   var priceA = a.price.find(p => p.currency == currency && p.model == 'package')
            // }
            let priceA = a.price[0].brutto
            let priceB = b.price[0].brutto
            if (priceA == priceB) sorted = 0
            if (key.order == "asc") {
              sorted = priceA - priceB
            }
            if (key.order == "desc") {
              sorted = priceB - priceA
            }
          }

          // title
          else if (key.by == "title") {
            let nameA = a.desc.title[this.siteLangCurrent].toUpperCase()
            let nameB = b.desc.title[this.siteLangCurrent].toUpperCase()
            if (nameA == nameB) sorted = 0
            if (key.order == "asc") {
              if (nameA < nameB) sorted = -1
              if (nameA > nameB) sorted = 1
            }
            if (key.order == "desc") {
              if (nameA > nameB) sorted = -1
              if (nameA < nameB) sorted = 1
            }
          }

          i++
        }

        return sorted
      })

      return orderedProducts
    },
    filter(products) {
      let filteredProducts = []
      let filterByTags =
        this.block &&
        this.block.config &&
        this.block.config.filterByTags &&
        this.block.config.filterByTags.length > 0
      let allowedTags =
        this.block && this.block.config && this.block.config.filterByTags
          ? this.block.config.filterByTags
          : []
      let filterByProductListingFilter = this.productListingFilter ? true : false

      // if there is no filter, return all
      if (!filterByTags && !filterByProductListingFilter) {
        return products
      }

      filteredProducts = products.filter((p) => {
        let pass = false

        // console.log('filter(): product:', p._display, pass, this.allowedItems.includes(p._display), JSON.parse(JSON.stringify(this.allowedItems)), p.sku)
        // show only visible products
        if (!this.allowedItems.includes(p._display)) return false

        // show only products with matching tag
        if (filterByTags) {
          if (p.tags && p.tags.length > 0) {
            let matchedTags = p.tags.filter((tag) => {
              return allowedTags.includes(tag)
            })
            // console.log('matchedTags:', matchedTags)
            if (matchedTags.length > 0) pass = true
            if (matchedTags.length == 0) pass = false
          } else {
            pass = false
          }
        }

        // show only products with matching product listing filter
        if (filterByProductListingFilter) {
          // product matches selected labels
          Object.keys(this.productListingFilter).forEach((labelId) => {
            // product contains label
            if (
              p.labels &&
              p.labels.labels &&
              p.labels.labels[labelId] &&
              p.labels.labels[labelId].length > 0
            ) {
              // label contains value
              if (
                p.labels.labels[labelId].findIndex(
                  (labelValue) => labelValue == this.productListingFilter[labelId]
                ) > -1
              ) {
                pass = true
              } else {
                pass = false
              }
            } else {
              pass = false
            }
          })
        }

        // show main only (hide variants)
        if (p.parentId != "main") pass = false

        return pass
      })

      return filteredProducts
    },
    async addListToCart() {
      this.addingListToCart = true

      // add first item and wait (creating new cart may be necessary)
      let firstItem = {
        ...this.shopShoppingListItems[0],
        open: this.shoppingListItemsCount == 1,
      }
      await this.shopCartItemAdd(firstItem)

      // add rest
      if (this.shoppingListItemsCount > 1) {
        await Promise.all(
          this.shopShoppingListItems.map(async (i, index) => {
            if (index != 0) {
              // ignore first item - already added
              let item = {
                ...i,
                open: index == this.shoppingListItemsCount - 1, // if last, open cart
              }
              await this.shopCartItemAdd(item)
            }
          })
        )
      }

      this.shopShoppingListClear()
      this.addingListToCart = false
    },
  },
}
</script>

<style scoped lang="less">
.no-products {
  width: 100%;
  padding: 20px 0;
  text-align: center;
}
</style>

<template>
  <div class="one-section-popup" :data-one-component="$options.name" v-bind="props.root">
    <OneContainer
      v-bind="props.container"
      :override="props.container"
      width="full"
      :verticalPadding="verticalPadding"
      class="bg-black/75 fixed inset-0 z-50"
      v-if="show"
    >
      <div class="box relative flex flex-col justify-center bg-white p-10 rounded-lg">
        <OneIcon
          class="absolute top-0 right-0"
          icon="Close"
          link
          @update:click="show = false"
          v-if="showCloseButton"
        />

        <p class="mb-10 text-center min-w-2xl">{{ text }}</p>
        <OneButton :label="buttonLabel" @update:click="onButtonClick" v-bind="button" />
      </div>
    </OneContainer>

    <div class="py-10 text-center" v-if="mode == 'admin'">[POP-UP]</div>
  </div>
</template>

<script>
import mixinClasses from "../../mixins/classes"
import {inject} from "vue"

export default {
  name: "OnePopup",
  mixins: [mixinClasses],
  props: {
    text: {
      type: String,
    },
    buttonLabel: {
      type: String,
    },
    button: {
      type: Object,
    },
    mode: {
      type: String,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    popupFrequency: {
      type: String,
      default: "everyTime", // once | oncePerSession | everyTime
    },
    siteId: {
      type: String,
    },
  },
  emits: ["update:onButtonClick"],
  setup() {
    const route = inject("VUE_ROUTER_USE_ROUTE")
    const router = inject("VUE_ROUTER_USE_ROUTER")

    return {
      route,
      router,
    }
  },
  data: () => ({
    show: false,
    defaults: {
      commonClasses: "",
      root: {
        class: "w-full",
      },
      container: {
        class: "flex-wrap flex flex-row items-center justify-center",
      },
      // card: {
      //   class: "pb-5 md:pb-0 last:pb-0",
      // },
    },
  }),
  computed: {
    // cardCount() {
    //   return this.cards ? this.cards.length : 0
    // },
    verticalPadding() {
      return this.variant == 2 ? 0 : 5
    },
    // containerWidth() {
    //   return this.variant == 2 ? "full" : "constrained"
    // },
    pageId() {
      if (!this.route || !this.route.meta) return
      return this.route.meta.id
    },
  },
  created() {},
  mounted() {
    if (this.mode == "admin") this.show = false
    else this.loadPopup()
  },
  methods: {
    onButtonClick(e) {
      console.log("onButtonClick")
      if (this.button.kind == "close") {
        this.show = false
      }
      this.$emit("update:onButtonClick", e)
    },
    loadPopup() {
      let storagePath = `@platformaone/client/site/${this.siteId}/page/${this.pageId}/popup`

      console.log("popup frequency:", this.popupFrequency)
      if (this.popupFrequency == "once") {
        sessionStorage.removeItem(`${storagePath}/doNotShow`)
        let doNotShow = localStorage.getItem(`${storagePath}/doNotShow`) === "true"

        if (!doNotShow) {
          this.show = true
          console.log("saving to local storage")
          localStorage.setItem(`${storagePath}/doNotShow`, "true")
        }
      } else if (this.popupFrequency == "oncePerSession") {
        localStorage.removeItem(`${storagePath}/doNotShow`)
        let doNotShow = sessionStorage.getItem(`${storagePath}/doNotShow`) === "true"

        if (!doNotShow) {
          this.show = true
          console.log("saving to session storage")
          sessionStorage.setItem(`${storagePath}/doNotShow`, "true")
        }
      } else if (this.popupFrequency == "everyTime") {
        localStorage.removeItem(`${storagePath}/doNotShow`)
        sessionStorage.removeItem(`${storagePath}/doNotShow`)
        this.show = true
      }
    },
  },
}
</script>

<style lang="less" scoped>
.box {
  min-width: 200px;
}
</style>
